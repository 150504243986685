import {cleanType} from './util';

export const getFilterTypes = input => {
  const type = cleanType(input);

  switch (type) {
    case 'datetime':
      return ['smallerthan', 'greaterthan', 'between', 'fixedperiod'];
    case 'string':
      return ['equals', 'notequals', 'contains', 'in', 'startswith', 'endswith'];
    case 'int32':
    case 'int64':
      return ['equals', 'notequals', 'smallerthan', 'greaterthan', 'between'];
    case 'guid':
      return ['equals', 'notequals'];
    case 'boolean':
      return ['equals', 'notequals'];
    default:
      return ['equals', 'notequals', 'smallerthan', 'greaterthan'];
  }
};
