import {QueryFilterValueSource} from '../../../app/api-generated/enums';
import {cleanType} from './util';

export const getFilterValueSources = filter => {
  const type = cleanType(filter);

  const available = [QueryFilterValueSource.Default, QueryFilterValueSource.DashboardCustom];

  //DashboardDateRange is a range for type date. This means it can only ever be applied to datetime times with a comparison of between
  if (type === 'datetime' && filter?.comparison === 'between')
    available.push(QueryFilterValueSource.DashboardDateRange);

  return available;
};
