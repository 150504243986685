var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class Dashboards {
    constructor() {
        this.paths = Object.freeze({
            getAssignableDashboards: () => `api/dashboards/GetAssignableDashboards`,
            get: (id, activednxthemeid) => {
                const __query = new QueryStringBuilder().add("activeDnxThemeId", activednxthemeid);
                return `api/dashboards/${encodeURIComponent(id)}${__query.toString()}`;
            },
            getVisibleDashboards: (areaid) => {
                const __query = new QueryStringBuilder().add("areaId", areaid);
                return `api/dashboards${__query.toString()}`;
            },
            create: () => `api/dashboards`,
            edit: () => `api/dashboards`,
            delete: (id) => `api/dashboards/${encodeURIComponent(id)}`,
            addUsersToDashboard: (id) => `api/dashboards/${encodeURIComponent(id)}/setusers`,
            getUsers: (id) => `api/dashboards/${encodeURIComponent(id)}/users`,
            getDateRangeOptions: () => `api/dashboards/daterangeoptions`,
            getDashboardFilterValues: (dashboardfilterid, search, divisionid) => {
                const __query = new QueryStringBuilder().add("search", search).add("divisionId", divisionid);
                return `api/dashboards/filtervaluesautocomplete/${encodeURIComponent(dashboardfilterid)}${__query.toString()}`;
            },
            export: (dashboardid, areaid, filters) => {
                const __query = new QueryStringBuilder().add("dashboardId", dashboardid).add("areaId", areaid).add("filters", filters);
                return `api/dashboards/export${__query.toString()}`;
            },
            listScheduledExportUsers: () => `api/dashboards/exports/users`,
            listScheduledExport: (dashboardid) => {
                const __query = new QueryStringBuilder().add("dashboardId", dashboardid);
                return `api/dashboards/exports/scheduled${__query.toString()}`;
            },
            createScheduledExport: () => `api/dashboards/exports/scheduled`,
            updateScheduledExport: () => `api/dashboards/exports/scheduled`,
            deleteScheduledExport: (id) => `api/dashboards/exports/delete/${encodeURIComponent(id)}`,
        });
    }
    /**
     * Dashboards.GetAssignableDashboards "GET api/dashboards/GetAssignableDashboards"
     *
     * Gets all dashboards that can be assigned to a user.
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<DashboardModel[]>>}
     */
    getAssignableDashboards(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/GetAssignableDashboards`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.Get "GET api/dashboards/{id}"
     * @param {Guid} id
     * @param {Guid} activednxthemeid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<DashboardWithWidgetsModel>>}
     */
    get(id, activednxthemeid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("activeDnxThemeId", activednxthemeid);
            let path = `api/dashboards/${id === undefined || id === null ? "" : encodeURIComponent(id)}${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.GetVisibleDashboards "GET api/dashboards"
     * @param {Guid} areaid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<DashboardModel[]>>}
     */
    getVisibleDashboards(areaid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("areaId", areaid);
            let path = `api/dashboards${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.Create "POST api/dashboards"
     *
     * Inserts the provided dashboard and its containing widgetgroups into the database
     *
     * 400 If the provided dashboard model is not valid
     * @param {DashboardCreateModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    create(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.Edit "PUT api/dashboards"
     *
     * Updates the provided dashboard and its containing widgetgroups. Please read remarks!
     *
     * NB!
     * This method will make sure that the provided dashboard will contain ONLY the widgetgroups that are specified in the request body.
     * So any previously existing widgetgroups that are not provided, will be deleted.
     * For existing widgetgroups, the Id property should be provided. If not provided, an insert will be performed instead of an update.
     *
     * 400 If the provided dashboard model is not valid, e.g. the Id property is missing
     *
     * 404 If the dashboard with the provided Id was not found
     * @param {DashboardEditModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    edit(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.Delete "DELETE api/dashboards/{id}"
     *
     * Deletes the dashboard with the provided id
     *
     * 404 If the dashboard with the provided id was not found
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    delete(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.AddUsersToDashboard "PUT api/dashboards/{id}/setusers"
     * @param {Guid} id
     * @param {Guid[]} userids
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    addUsersToDashboard(id, userids, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/${id === undefined || id === null ? "" : encodeURIComponent(id)}/setusers`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, userids);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.GetUsers "GET api/dashboards/{id}/users"
     *
     * Get an array of all users the dashboard with id is available to.
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<DashboardUserModel[]>>}
     */
    getUsers(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/${id === undefined || id === null ? "" : encodeURIComponent(id)}/users`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.GetDateRangeOptions "GET api/dashboards/daterangeoptions"
     *
     * Gets all available date range options
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<EnumDescription[]>>}
     */
    getDateRangeOptions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/daterangeoptions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.GetDashboardFilterValues "GET api/dashboards/filtervaluesautocomplete/{dashboardFilterId}"
     * @param {Guid} dashboardfilterid
     * @param {string} search
     * @param {Guid} divisionid
     * @param {CancellationToken} cancellationtoken
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<string[]>>}
     */
    getDashboardFilterValues(dashboardfilterid, search, divisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("search", search).add("divisionId", divisionid);
            let path = `api/dashboards/filtervaluesautocomplete/${dashboardfilterid === undefined || dashboardfilterid === null ? "" : encodeURIComponent(dashboardfilterid)}${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.Export "GET api/dashboards/export"
     * @param {Guid} dashboardid
     * @param {Guid} areaid
     * @param {DashboardExportFilter[]} filters
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    export(dashboardid, areaid, filters, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("dashboardId", dashboardid).add("areaId", areaid).add("filters", filters);
            let path = `api/dashboards/export${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.ListScheduledExportUsers "GET api/dashboards/exports/users"
     *
     * List all users eligible for receiving dashboard exports
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<UserSummaryModel[]>>} List of users
     */
    listScheduledExportUsers(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/exports/users`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.ListScheduledExport "GET api/dashboards/exports/scheduled"
     *
     * List all exports
     * @param {Guid} dashboardid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<DashboardExportModel[]>>}
     */
    listScheduledExport(dashboardid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("dashboardId", dashboardid);
            let path = `api/dashboards/exports/scheduled${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.CreateScheduledExport "POST api/dashboards/exports/scheduled"
     * @param {DashboardExportModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    createScheduledExport(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/exports/scheduled`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.UpdateScheduledExport "PUT api/dashboards/exports/scheduled"
     * @param {DashboardExportModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    updateScheduledExport(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/exports/scheduled`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Dashboards.DeleteScheduledExport "DELETE api/dashboards/exports/delete/{id}"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    deleteScheduledExport(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dashboards/exports/delete/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const dashboards = new Dashboards();
export { dashboards as default, Dashboards };
