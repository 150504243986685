class JsonRequestBodyWriter {
    write(request, body) {
        request.headers = Object.assign(Object.assign({}, request.headers), { 'Content-Type': 'application/json' });
        request.body = JSON.stringify(body);
    }
}
class FormRequestBodyWriter {
    write(request, body) {
        const formData = new FormData();
        this.appendToFormData(formData, body);
        request.body = formData;
    }
    appendToFormData(formData, obj, path = '') {
        //formData internally stringifies certain values, leading to undefined/null being represented as string literals containing `undefined`/`null`
        if (obj === undefined || obj === null) {
            return;
        }
        //Simple values should always be directly embedded
        //Blobs being an exception to the rule however - they can be considered files and directly appended
        if (typeof obj !== 'object' || obj instanceof Blob) {
            formData.append(path, obj);
            return;
        }
        //Files from a FileList must be added using the path of the FileList
        //The webserver will in turn bind this value to a collection of FormFiles
        if (obj instanceof FileList) {
            for (let index = 0; index < obj.length; index++) {
                formData.append(path, obj[index]);
            }
            return;
        }
        //Collections need to be enumerated and added one by one
        if (Array.isArray(obj)) {
            for (let index = 0; index < obj.length; index++) {
                this.appendToFormData(formData, obj[index], `${path}[${index}]`);
            }
            return;
        }
        //Objects need to be enumerated to have their individual properties added
        Object.keys(obj).forEach(key => this.appendToFormData(formData, obj[key], path ? `${path}.${key}` : key));
    }
}
const jsonRequestBodyWriter = new JsonRequestBodyWriter();
const formRequestBodyWriter = new FormRequestBodyWriter();
export { jsonRequestBodyWriter, formRequestBodyWriter };
