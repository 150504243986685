import {T, format} from '@dnx/core';

export const cleanType = input => {
  let type = input?.column?.type ?? input?.type ?? input;
  if (typeof type !== 'string') return undefined;

  if (type.endsWith('?')) {
    type = type.substring(0, type.length - 1);
  }

  return type.toLowerCase();
};

/** Get the label/placeholder for the given filtername/comparison */
export function formatLabel(name, comparison) {
  const comparisonName = T(`WIDGET_QUERYFILTER_${comparison.toUpperCase()}`);
  const filterName = `${T(name)}`;

  //If no resource is found, do not add comparison.
  const comparisonString = comparisonName.toLowerCase().includes('widget queryfilter') ? '' : ` (${comparisonName})`;
  return `${filterName}${comparisonString}`;
}

/** Format the given value for display for the given datatype */
export function formatValue(value, type) {
  type = cleanType(type);

  // are we dealing with a tuple?
  try {
    const [left, right] = JSON.parse(value);
    return `${format(left, type)} - ${format(right, type)}`;
  } catch (e) {
    /* Swallow */
  }

  return format(value, type);
}
