export default class QueryStringBuilder {
    constructor() {
        this.parameters = {};
    }
    add(key, value) {
        // serialize by index, understood by ASP, also allows serializing complex objects
        if (Array.isArray(value)) {
            for (const idx in value) {
                this.add(`${key}[${idx}]`, value[idx]);
            }
            return this;
        }
        // typeof null evaluates to object, skip null
        // serialize as `key.value`, understood by ASP
        if (typeof value === 'object' &&
            value !== null) {
            for (const i of Object.keys(value)) {
                this.add(`${key}.${i}`, value[i]);
            }
            return this;
        }
        if (typeof key === 'string' &&
            typeof value !== 'undefined' &&
            value !== null) {
            this.parameters[key] = value;
            return this;
        }
        if (typeof key === 'object') {
            const obj = key;
            for (const i of Object.keys(obj)) {
                this.add(i, obj[i]);
            }
            return this;
        }
        return this;
    }
    clear() {
        this.parameters = {};
        return this;
    }
    toString() {
        if (Object.keys(this.parameters).length == 0) {
            return '';
        }
        const result = [];
        for (const key of Object.keys(this.parameters)) {
            const value = this.parameters[key];
            result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
        return '?' + result.join('&');
    }
}
