var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createResetMutation, T, $can, addToast } from '@dnx/core';
import widgets from '../../app/api-generated/widgets';
import queryBuilder from '../../app/api-generated/queryBuilder';
import { GraphType } from '../typedefs/dashboards';
import { widgetOption } from '../components/WidgetConfigOption';
import validation from '../validation';
const _mostRecentWidgetDataRequests = {};
const GET_INITIAL_STATE = () => ({
    themes: null,
    themeId: undefined,
    graphTypes: null,
    allDashboardFilters: [],
    widget: null,
    bulkDeletedWidgetsMessage: undefined,
    queryData: {},
    widgetName: '',
    widgetId: undefined,
    type: undefined,
    groupId: undefined,
    series: undefined,
    categories: undefined,
    xaxisType: undefined,
    mapId: null,
    sqlEditor: false,
    query: undefined,
    sequences: [],
    graphConfig: {
        apiUrl: undefined,
    },
    gaugeConfig: {
        min: undefined,
        max: undefined,
        green: undefined,
        orange: undefined,
        red: undefined,
    },
    widgetWidth: undefined,
    widgetHeight: undefined,
    defaultPageSize: 15,
    showTableColumnTotals: false,
    stacked: false,
    horizontal: false,
    dataLabels: false,
    zoom: false,
    stackType: 'normal',
    lineType: 'smooth',
    information: '',
    storedProcedure: undefined,
    operatorSchemeId: undefined,
    cacheLifetimeInMinutes: 0,
    dashboardFilters: [],
    cqDashboardFilters: [], // id's for custom queries
    dataEntity: undefined,
    customSqlQuery: false,
    debugMode: false,
    isInitialized: false,
    realtime: false,
    refreshInterval: undefined,
    synchGroup: undefined,
    updateData: {},
    annotations: [],
    displayType: undefined,
    numberCustomDisplayType: undefined,
    numberDecimalAmount: 1,
    numberSubtitle: undefined,
    comparisonText: '',
    activeWidgetFilters: new Map(),
});
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    getters: {
        getThemes: state => state.themes,
        getThemeId: state => state.themeId,
        getGraphTypes: state => state.graphTypes,
        getAllDashboardFilters: state => state.allDashboardFilters,
        getWidget: state => state.widget,
        getBulkDeletedWidgetsMessage: state => state.bulkDeletedWidgetsMessage,
        getQueryData: state => state.queryData,
        getUpdateData: state => state.updateData,
        getWidgetName: state => state.widgetName,
        getWidgetId: state => state.widgetId,
        getType: state => state.type,
        getGroupId: state => state.groupId,
        getSeries: state => state.series,
        getCategories: state => state.categories,
        getXaxisType: state => state.xaxisType,
        getMapId: state => state.mapId,
        getSqlEditor: state => state.sqlEditor,
        getQuery: state => state.query,
        getSequences: state => state.sequences,
        getGaugeConfig: state => state.gaugeConfig,
        getGraphConfig: state => state.graphConfig,
        getWidgetWidth: state => state.widgetWidth,
        getWidgetHeight: state => state.widgetHeight,
        getDefaultPageSize: state => state.defaultPageSize,
        getShowTableColumnTotals: state => state.showTableColumnTotals,
        getStacked: state => state.stacked,
        getHorizontal: state => state.horizontal,
        getDataLabels: state => state.dataLabels,
        getZoom: state => state.zoom,
        getStackType: state => state.stackType,
        getLineType: state => state.lineType,
        getInformation: state => state.information,
        getStoredProcedure: state => state.storedProcedure,
        getOperatorSchemeId: state => state.operatorSchemeId,
        getCacheLifetimeInMinutes: state => state.cacheLifetimeInMinutes,
        getDashboardFilters: state => state.dashboardFilters,
        getCustomQueryDashboardFilterIds: state => state.cqDashboardFilters,
        getDataEntity: state => state.dataEntity,
        getDebugMode: state => state.debugMode,
        getIsInitialized: state => state.isInitialized,
        getRefreshInterval: state => state.refreshInterval,
        getSynchGroup: state => state.synchGroup,
        getRealtime: state => state.realtime,
        getAnnotations: state => state.annotations,
        getDisplayType: state => state.displayType,
        getNumberCustomDisplayType: state => state.numberCustomDisplayType,
        getNumberDecimalAmount: state => state.numberDecimalAmount,
        getNumberSubtitle: state => state.numberSubtitle,
        getComparisonText: state => state.comparisonText,
        getIsCustomSqlQuery(state, getters, rootState, rootGetters) {
            //QB isSupported flag should be moved elsewhere, fine for now though
            return state.customSqlQuery || !rootGetters['queryBuilder/isSupported'] || state.debugMode;
        },
        getThemeColors: state => {
            var _a;
            //Table widgets dont support theming
            const selectedTheme = state.type === GraphType.table ? null : (_a = state.themes) === null || _a === void 0 ? void 0 : _a.find(theme => theme.id === state.themeId);
            //Default to grey palette
            return selectedTheme ? [...selectedTheme.colors] : ['#cccccc', '#bbbbbb', '#eeeeee'];
        },
        getSerieHasData(state) {
            //Graph doesn't support the 'series' option
            if (state.type === GraphType.graph || state.type === GraphType.map) {
                return true;
            }
            if (state.query)
                return true;
            if (Array.isArray(state.series)) {
                return !!state.series.length;
            }
            if (typeof state.series === 'object') {
                return !!Object.keys(state.series).length;
            }
            return false;
        },
        /**
         * Bit determining whether or not to show the Information step
         * @param state
         * @param getters
         * @return {boolean}
         */
        getStep3: (state, getters) => {
            if (state.widgetWidth === undefined || !state.type || !state.groupId) {
                return false;
            }
            //Not all types support theming
            if (state.type === GraphType.table ||
                state.type === GraphType.gauge ||
                state.type === GraphType.graph ||
                state.type === GraphType.number ||
                state.type === GraphType.map) {
                return true;
            }
            return !!state.themeId;
        },
        /**
         * data validation - checking whether the data is numeric for relevant charts
         * @param state
         * @return {boolean}
         */
        isCorrectXAxisType: state => {
            if (widgetOption.numericGraphTypes.includes(state.type) && state.xaxisType !== 'numeric') {
                return false;
            }
            return true;
        },
        /**
         * Registering possible errors in the widget builder
         * @param state
         * @param getters
         * @return {Array}
         */
        errors: (state, getters, rootState, rootGetters) => (reloadButton = false) => {
            validation.reset();
            // validation only needed for publishing not reloading data
            if (!reloadButton) {
                if (!state.widgetName)
                    validation.error(T('WIDGET_CONFIG_ERROR_WIDGETNAME'));
            }
            // if (!getters.isCorrectXAxisType)
            //   validation.error(T('ERROR_WIDGET_GRAPH_DATA_MUST_BE_NUMERIC'));
            if (!state.type)
                validation.error(T('WIDGET_CONFIG_ERROR_GRAPHTYPE'));
            if (!state.groupId)
                validation.error(T('WIDGET_CONFIG_ERROR_GROUPID'));
            // TODO this needs a validation about the y-axis and not the x-axis
            // if (!getters.isCorrectXAxisType) validation.error(T('ERROR_WIDGET_GRAPH_DATA_MUST_BE_NUMERIC'));
            if (!getters.getIsCustomSqlQuery && rootGetters['queryBuilder/getState'].groups.length === 0)
                validation.error(T('WIDGET_CONFIG_ERROR_INCOMPLETE_DATA_SERIES'));
            if (getters.getIsCustomSqlQuery && state.type !== GraphType.map && !getters.getQuery)
                validation.error(T('WIDGETS_QUERY_BUILDER_INVALID_QUERY'));
            return [...validation];
        },
        /**
         * Determining whether the widget can be saved or not (based on previous steps and errors)
         * @param getters
         * @return {boolean}
         */
        canSave: (state, getters) => {
            if (!getters.getStep3) {
                return false;
            }
            if (getters.errors().length > 0) {
                return false;
            }
            return true;
        },
    },
    actions: {
        fetchThemes(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield widgets.getColorThemes();
                commit('setThemes', response.value);
            });
        },
        createTheme(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ dispatch }, data) {
                const response = yield widgets.createColorTheme(data);
                if (response.success) {
                    addToast(response.success ? 'success' : 'error', response.success ? T('THEMES_NEW_THEME_SAVED') : T('THEMES_CHANGES_CANNOT_BE_SAVED'));
                }
                dispatch('fetchThemes');
            });
        },
        updateTheme(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ dispatch }, data) {
                const response = yield widgets.editColorTheme(data);
                addToast(response.success ? 'success' : 'error', response.success ? T('THEMES_CHANGES_SUCCESSFULLY_SAVED') : T('THEMES_CHANGES_CANNOT_BE_SAVED'));
                dispatch('fetchThemes');
            });
        },
        deleteTheme(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit, dispatch }, data) {
                const response = yield widgets.deleteColorTheme(data);
                if (response.success) {
                    dispatch('fetchThemes');
                }
                return response;
            });
        },
        fetchGraphTypes(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield widgets.graphTypes();
                commit('setGraphTypes', response.value);
            });
        },
        fetchDashboardFilters(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield widgets.getDashboardFilters();
                commit('setAllDashboardFilters', response.value);
            });
        },
        fetchWidget(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, data) {
                const response = yield widgets.get(data);
                commit('setWidget', response.value);
                commit('queryBuilder/setWidget', response.value, { root: true });
            });
        },
        createWidget(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, data) {
                const response = yield widgets.create(data);
                commit('setWidget', response.value);
                commit('queryBuilder/setWidget', response.value, { root: true });
                commit('setWidgetId', response.value.id);
            });
        },
        updateWidget(ctx, data) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield widgets.edit(data);
                ctx.commit('setWidget', response.value);
                ctx.commit('queryBuilder/setWidget', response.value, { root: true });
                return response;
            });
        },
        // eslint-disable-next-line no-empty-pattern
        deleteWidget(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({}, data) {
                yield widgets.delete(data);
            });
        },
        bulkDeleteWidgets(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, data) {
                const response = yield widgets.deleteAllFromGroup(data);
                const message = T('WIDGET_BULK_DELETE_MESSAGE', {
                    amount: `${response.value} ${response.value === 1 ? 'widget' : 'widgets'}`,
                });
                commit('setBulkDeletedWidgetsMessage', message);
            });
        },
        // eslint-disable-next-line no-empty-pattern
        moveWidget(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({}, data) {
                yield widgets.moveWidget(data.id, data.position);
            });
        },
        /**
         * Persist current draft query and load a preview dataset based on the new draft query
         * @param ctx
         * @return {Promise}
         */
        fetchDraftPreviewData(ctx) {
            return __awaiter(this, void 0, void 0, function* () {
                //In case of a custom query, an actual query is required prior to being able to load data
                //In case of a QB model, QB model should be considered valid
                if ((ctx.getters.getIsCustomSqlQuery && !ctx.state.query) ||
                    (!ctx.getters.getIsCustomSqlQuery && !ctx.rootGetters['queryBuilder/isValid'])) {
                    //Remove data if querydata is not valid
                    ctx.commit('setDraftQueryData', {});
                    return;
                }
                //Step one: Save our draft query
                //If we're a custom SQL widget, persist our given draft query
                //If we're a QB widget, persist our QB model + sync our QB sequences, sequences are based on QB configuration
                if (ctx.getters.getIsCustomSqlQuery) {
                    yield widgets.saveDraft(ctx.state.widgetId, ctx.state.query);
                }
                else {
                    //Ensure QB is initialized
                    yield ctx.rootGetters['queryBuilder/getWidgetAwaitable'].awaitable;
                    ctx.commit('setSequences', ctx.rootGetters['queryBuilder/getSequences']);
                    yield queryBuilder.setDraftQuery(ctx.state.widgetId, ctx.rootGetters['queryBuilder/getPreviewState']);
                }
                //todo: add filtersets v
                const payload = {
                    id: ctx.state.widgetId,
                    widgetType: ctx.state.type,
                    sequences: ctx.state.sequences,
                    language: ctx.rootGetters['locale/preferredLanguage'],
                };
                //Step two: Reload our data
                const previewResponse = yield ctx.dispatch('fetchMostRecentData', {
                    fetcher: () => widgets.previewWidgetData(payload.id, payload),
                    id: payload.id,
                });
                if (!previewResponse) {
                    return;
                }
                if (!previewResponse.success) {
                    if (previewResponse.value.message) {
                        ctx.commit('setDraftQueryData', `${previewResponse.value.message}`);
                    }
                    else {
                        ctx.commit('setDraftQueryData', `${previewResponse.value.error}`);
                    }
                }
                else {
                    ctx.commit('setDraftQueryData', previewResponse.value);
                }
            });
        },
        fetchQueryData(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ state, commit, dispatch, rootGetters }, data) {
                var _b, _c, _d, _e;
                const baseData = {
                    divisionId: rootGetters['areas/getSelectedAreaId'],
                    start: (_b = rootGetters['dashboards/getDateRangeFilter']) === null || _b === void 0 ? void 0 : _b.startDate,
                    end: (_c = rootGetters['dashboards/getDateRangeFilter']) === null || _c === void 0 ? void 0 : _c.endDate,
                    language: rootGetters['locale/preferredLanguage'],
                    dashboardFilters: (_d = state.activeWidgetFilters.get(data.id)) !== null && _d !== void 0 ? _d : [],
                };
                // attempt to infer type from widget collection, when editing, assume a type is explicitly passed in
                if (data.id)
                    baseData.widgetType = (_e = rootGetters['dashboards/getWidget'](data.id)) === null || _e === void 0 ? void 0 : _e.widgetType;
                data = Object.assign(Object.assign({}, baseData), data);
                const response = yield dispatch('fetchMostRecentData', {
                    fetcher: () => widgets.getWidgetData(data.id, data),
                    id: data.id,
                });
                if (!response) {
                    return;
                }
                // data gathererer hier --> fetch voor specifiek widgetid, gatherdata for widget
                // In case of update we send data using a different property to prevent full chart rebuild and just update the (internal) series.
                if (data.update) {
                    if (data.append)
                        commit('setUpdateData', {
                            data: Object.assign(Object.assign({}, response.value), { append: true }),
                            id: data.id,
                        });
                    else
                        commit('setUpdateData', { data: response.value, id: data.id });
                    return;
                }
                if (data.preview) {
                    commit('setDraftQueryData', response.value);
                }
                else {
                    commit('setQueryData', { data: response.value, id: data.id });
                }
            });
        },
        /**
         * Fetch data using the given fetcher function for the given id
         * when another call was scheduled prior to completion, resolves to undefined
         * @param ctx
         * @param fetcher
         * @param id
         * @return {Promise<*>}
         */
        fetchMostRecentData(ctx_1, _a) {
            return __awaiter(this, arguments, void 0, function* (ctx, { fetcher, id }) {
                //TODO: Use SchedulerService from maps store
                const getWidgetDataCall = fetcher();
                _mostRecentWidgetDataRequests[id] = getWidgetDataCall;
                let response;
                try {
                    response = yield getWidgetDataCall;
                }
                catch (e) {
                    //If we were the most recent call AND caused an error, let our error bubble
                    //otherwise swallow, our most recent call may or may not have succeeded
                    if (_mostRecentWidgetDataRequests[id] === getWidgetDataCall) {
                        //Prevent keeping resources in memory even if our call failed
                        delete _mostRecentWidgetDataRequests[id];
                        throw e;
                    }
                    return;
                }
                //Another call was scheduled for the same widget in the meantime - discard our current result
                //let the more recent call run to completion
                if (_mostRecentWidgetDataRequests[id] !== getWidgetDataCall) {
                    return;
                }
                //Mark our call as done, prevent keeping resources in memory
                delete _mostRecentWidgetDataRequests[id];
                return response;
            });
        },
        deleteWidgetDataKey({ commit }, data) {
            commit('setDeleteWidgetDataKey', data);
        },
        // eslint-disable-next-line no-empty-pattern
        exportWidgetToExcel(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({}, data) {
                //TODO: (new feature) to be done in dnds graphs and api-generator
                //await widgets.exportWidgetToExcel(data);
                console.log(`Export to excel not yet supported: ${data}`);
            });
        },
        toggleDebugMode(ctx) {
            return __awaiter(this, void 0, void 0, function* () {
                if (!$can.read.widgetqueries) {
                    return;
                }
                ctx.commit('setDebugMode', !ctx.state.debugMode);
            });
        },
    },
    mutations: {
        reset: createResetMutation(GET_INITIAL_STATE),
        resetWidgetConfig() {
            this.commit('widgets/reset');
            this.commit('queryBuilder/reset');
        },
        setThemes: (state, data) => (state.themes = data),
        setThemeId: (state, data) => (state.themeId = data),
        setGraphTypes: (state, data) => (state.graphTypes = data),
        setAllDashboardFilters: (state, data) => (state.allDashboardFilters = data),
        setWidget: (state, data) => (state.widget = data),
        setBulkDeletedWidgetsMessage: (state, data) => (state.bulkDeletedWidgetsMessage = data),
        setDraftQueryData: (state, data) => (state.queryData = data),
        setQueryData: (state, data) => (state.queryData[data.id] = data.data),
        setDeleteWidgetDataKey: (state, data) => delete state.queryData[data],
        resetQueryData: state => (state.queryData = {}),
        setWidgetName: (state, data) => (state.widgetName = data),
        setWidgetId: (state, data) => (state.widgetId = data),
        setType: (state, data) => (state.type = data),
        setGroupId: (state, data) => (state.groupId = data),
        setSeries: (state, data) => (state.series = data),
        setCategories: (state, data) => (state.categories = data),
        setXaxisType: (state, data) => (state.xaxisType = data),
        setMapId: (state, data) => (state.mapId = data),
        setSqlEditor: (state, data) => (state.sqlEditor = data),
        setQuery: (state, data) => (state.query = data),
        setSequences: (state, data) => (state.sequences = data),
        pushIntoSequences: (state, data) => Array.isArray(data) ? Array.prototype.push.apply(state.sequences, data) : state.sequences.push(data),
        setSequencePropertyName: (state, data) => (state.sequences[data.index].propertyName = data.propertyName),
        setSequenceDisplayName: (state, data) => {
            const sequence = state.sequences[data.index];
            const useDefault = !data.displayName;
            sequence.displayName = data.displayName;
            // managed by system when cleared, managed by user when manually entered
            sequence.isDefaultDisplayName = useDefault;
        },
        setColumnCalculationType: (state, data) => (state.sequences[data.index].calcType = data.calcType),
        setSequenceType: (state, data) => (state.sequences[data.index].type = data.type),
        setSequenceIsInvisible: (state, data) => (state.sequences[data.index].isInvisible = data.isInvisible),
        setGaugeConfig: (state, data) => (state.gaugeConfig = data),
        setSingleGaugeConfigProperty: (state, data) => (state.gaugeConfig[data.key] = data.value),
        setGraphConfig: (state, data) => (state.graphConfig = data),
        setSingleGraphConfigProperty: (state, data) => (state.graphConfig[data.key] = data.value),
        setWidgetWidth: (state, data) => (state.widgetWidth = data),
        setWidgetHeight: (state, data) => (state.widgetHeight = data),
        setDefaultPageSize: (state, data) => (state.defaultPageSize = data),
        setShowTableColumnTotals: (state, data) => (state.showTableColumnTotals = data),
        setStacked(state, data) {
            state.stacked = data;
            // reset stacktype to default if not stacked:
            state.stackType = data ? state.stackType : GET_INITIAL_STATE().stackType;
        },
        setHorizontal: (state, data) => (state.horizontal = data),
        setDataLabels: (state, data) => (state.dataLabels = data),
        setZoom: (state, data) => (state.zoom = data),
        setStackType: (state, data) => (state.stackType = data),
        setLineType: (state, data) => (state.lineType = data),
        setInformation: (state, data) => (state.information = data),
        setStoredProcedure: (state, data) => (state.storedProcedure = data),
        setOperatorSchemeId: (state, data) => (state.operatorSchemeId = data),
        setCacheLifetimeInMinutes: (state, data) => (state.cacheLifetimeInMinutes = data),
        setDashboardFilters: (state, data) => (state.dashboardFilters = data),
        setCqDashboardFilters: (state, data) => (state.cqDashboardFilters = data),
        setDataEntity: (state, data) => (state.dataEntity = data),
        setIsCustomSqlQuery: (state, data) => (state.customSqlQuery = data),
        setDebugMode: (state, data) => (state.debugMode = data),
        markInitialized: state => (state.isInitialized = true),
        setRefreshInterval: (state, data) => (state.refreshInterval = data),
        setRealtime: (state, data) => (state.realtime = data === undefined ? data : !!data),
        setSynchGroup: (state, data) => (state.synchGroup = data),
        setUpdateData: (state, data) => (state.updateData[data.id] = data.data),
        setDisplayType: (state, data) => (state.displayType = data),
        setNumberCustomDisplayType: (state, data) => (state.numberCustomDisplayType = data),
        setNumberDecimalAmount(state, data) {
            if (data === 0 || data <= 3) {
                state.numberDecimalAmount = data;
            }
        },
        setNumberSubtitle: (state, data) => (state.numberSubtitle = data),
        setComparisonText: (state, data) => (state.comparisonText = data),
        setAnnotations: (state, data) => (state.annotations = data),
        addAnnotation(state) {
            state.annotations.push({
                type: 4,
                text: '',
                color: '#2783C6',
                isInvisible: false,
            });
        },
        updateAnnotation(state, data) {
            state.annotations[data.index][data.key] = data.value;
            // Clear the axis property to make sure unintended values are saved
            if (data.key === 'type') {
                delete state.annotations[data.index].x;
                delete state.annotations[data.index].x2;
                delete state.annotations[data.index].y;
                delete state.annotations[data.index].y2;
            }
        },
        deleteAnnotation: (state, index) => state.annotations.splice(index, 1),
        /**
         * Set the active filters for the given widget
         * @param state
         * @param {String} widgetId
         * @param { Array } filters
         * @return {Map<any, any>}
         */
        setWidgetFilters: (state, { widgetId, filters }) => state.activeWidgetFilters.set(widgetId, filters),
        resetWidgetFilters: state => {
            state.activeWidgetFilters.clear();
        },
    },
};
