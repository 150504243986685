export var ApiTokenType;
(function (ApiTokenType) {
    ApiTokenType[ApiTokenType["OAuth"] = 0] = "OAuth";
    ApiTokenType[ApiTokenType["Static"] = 1] = "Static";
    ApiTokenType[ApiTokenType["Basic"] = 2] = "Basic";
    ApiTokenType[ApiTokenType["Aws"] = 3] = "Aws";
})(ApiTokenType || (ApiTokenType = {}));
export var DateRangeOption;
(function (DateRangeOption) {
    DateRangeOption[DateRangeOption["Today"] = 1] = "Today";
    DateRangeOption[DateRangeOption["Yesterday"] = 2] = "Yesterday";
    DateRangeOption[DateRangeOption["ThisWeekSunday"] = 3] = "ThisWeekSunday";
    DateRangeOption[DateRangeOption["ThisWeekMonday"] = 4] = "ThisWeekMonday";
    DateRangeOption[DateRangeOption["LastWeekSundaySaturday"] = 5] = "LastWeekSundaySaturday";
    DateRangeOption[DateRangeOption["LastWeekMondaySunday"] = 6] = "LastWeekMondaySunday";
    DateRangeOption[DateRangeOption["LastWorkweek"] = 7] = "LastWorkweek";
    DateRangeOption[DateRangeOption["ThisMonth"] = 8] = "ThisMonth";
    DateRangeOption[DateRangeOption["LastMonth"] = 9] = "LastMonth";
    DateRangeOption[DateRangeOption["ThisYear"] = 10] = "ThisYear";
    DateRangeOption[DateRangeOption["Tomorrow"] = 11] = "Tomorrow";
    DateRangeOption[DateRangeOption["NextWeek"] = 12] = "NextWeek";
    DateRangeOption[DateRangeOption["LastYear"] = 13] = "LastYear";
    DateRangeOption[DateRangeOption["LastTwoWeeks"] = 14] = "LastTwoWeeks";
})(DateRangeOption || (DateRangeOption = {}));
export var WidgetType;
(function (WidgetType) {
    WidgetType[WidgetType["Draft"] = 0] = "Draft";
    WidgetType[WidgetType["Bar"] = 1] = "Bar";
    WidgetType[WidgetType["Pie"] = 2] = "Pie";
    WidgetType[WidgetType["Mixed"] = 3] = "Mixed";
    WidgetType[WidgetType["Bubble"] = 4] = "Bubble";
    WidgetType[WidgetType["Area"] = 5] = "Area";
    WidgetType[WidgetType["Candlestick"] = 6] = "Candlestick";
    WidgetType[WidgetType["Table"] = 7] = "Table";
    WidgetType[WidgetType["Line"] = 8] = "Line";
    WidgetType[WidgetType["Gauge"] = 9] = "Gauge";
    WidgetType[WidgetType["Graph"] = 10] = "Graph";
    WidgetType[WidgetType["ScatterPlot"] = 11] = "ScatterPlot";
    WidgetType[WidgetType["Funnel"] = 12] = "Funnel";
    WidgetType[WidgetType["Map"] = 13] = "Map";
    WidgetType[WidgetType["Brush"] = 14] = "Brush";
    WidgetType[WidgetType["DynamicChart"] = 15] = "DynamicChart";
    WidgetType[WidgetType["Radar"] = 16] = "Radar";
    WidgetType[WidgetType["Number"] = 17] = "Number";
    WidgetType[WidgetType["WordCloud"] = 18] = "WordCloud";
})(WidgetType || (WidgetType = {}));
export var QueryComparisonEquality;
(function (QueryComparisonEquality) {
    QueryComparisonEquality[QueryComparisonEquality["IsNull"] = 0] = "IsNull";
    QueryComparisonEquality[QueryComparisonEquality["IsNotNull"] = 1] = "IsNotNull";
    QueryComparisonEquality[QueryComparisonEquality["Equals"] = 2] = "Equals";
    QueryComparisonEquality[QueryComparisonEquality["NotEquals"] = 3] = "NotEquals";
    QueryComparisonEquality[QueryComparisonEquality["GreaterThan"] = 4] = "GreaterThan";
    QueryComparisonEquality[QueryComparisonEquality["SmallerThan"] = 5] = "SmallerThan";
    QueryComparisonEquality[QueryComparisonEquality["In"] = 6] = "In";
    QueryComparisonEquality[QueryComparisonEquality["Between"] = 7] = "Between";
    QueryComparisonEquality[QueryComparisonEquality["Contains"] = 8] = "Contains";
    QueryComparisonEquality[QueryComparisonEquality["StartsWith"] = 9] = "StartsWith";
    QueryComparisonEquality[QueryComparisonEquality["EndsWith"] = 10] = "EndsWith";
    QueryComparisonEquality[QueryComparisonEquality["FixedPeriod"] = 11] = "FixedPeriod";
})(QueryComparisonEquality || (QueryComparisonEquality = {}));
export var QueryFilterValueSource;
(function (QueryFilterValueSource) {
    QueryFilterValueSource[QueryFilterValueSource["Default"] = 0] = "Default";
    QueryFilterValueSource[QueryFilterValueSource["DashboardCustom"] = 1] = "DashboardCustom";
    QueryFilterValueSource[QueryFilterValueSource["DashboardDateRange"] = 2] = "DashboardDateRange";
})(QueryFilterValueSource || (QueryFilterValueSource = {}));
export var WidgetDataLabelType;
(function (WidgetDataLabelType) {
    WidgetDataLabelType[WidgetDataLabelType["Category"] = 1] = "Category";
    WidgetDataLabelType[WidgetDataLabelType["Numeric"] = 2] = "Numeric";
    WidgetDataLabelType[WidgetDataLabelType["DateTime"] = 3] = "DateTime";
})(WidgetDataLabelType || (WidgetDataLabelType = {}));
export var FileShareType;
(function (FileShareType) {
    FileShareType[FileShareType["LocalFolder"] = 0] = "LocalFolder";
    FileShareType[FileShareType["HomeFolder"] = 1] = "HomeFolder";
    FileShareType[FileShareType["Documentation"] = 2] = "Documentation";
})(FileShareType || (FileShareType = {}));
export var VfsUriKind;
(function (VfsUriKind) {
    /**
     * Unknown type
     */
    VfsUriKind[VfsUriKind["Unspecified"] = 0] = "Unspecified";
    /**
     * Somewhat comparable to the UrlKind.Absolute
     * Points to a file in a specific file system type
     */
    VfsUriKind[VfsUriKind["Specialized"] = 1] = "Specialized";
    /**
     * Somewhat comparable to the UrlKind.Relative
     * vfs:// scheme, paths should be treated as relative to the filesystem root
     */
    VfsUriKind[VfsUriKind["Generic"] = 2] = "Generic";
})(VfsUriKind || (VfsUriKind = {}));
export var VfsUriCaseOption;
(function (VfsUriCaseOption) {
    VfsUriCaseOption[VfsUriCaseOption["CaseSensitive"] = 0] = "CaseSensitive";
    VfsUriCaseOption[VfsUriCaseOption["IgnoreCase"] = 1] = "IgnoreCase";
})(VfsUriCaseOption || (VfsUriCaseOption = {}));
export var ColumnCategory;
(function (ColumnCategory) {
    ColumnCategory[ColumnCategory["Text"] = 1] = "Text";
    ColumnCategory[ColumnCategory["Numeric"] = 2] = "Numeric";
    ColumnCategory[ColumnCategory["DateTime"] = 3] = "DateTime";
    ColumnCategory[ColumnCategory["Bit"] = 4] = "Bit";
})(ColumnCategory || (ColumnCategory = {}));
export var Gender;
(function (Gender) {
    Gender[Gender["Unknown"] = 0] = "Unknown";
    Gender[Gender["Male"] = 1] = "Male";
    Gender[Gender["Female"] = 2] = "Female";
})(Gender || (Gender = {}));
export var SequenceType;
(function (SequenceType) {
    SequenceType[SequenceType["Column"] = 1] = "Column";
    SequenceType[SequenceType["Line"] = 2] = "Line";
    SequenceType[SequenceType["Area"] = 3] = "Area";
})(SequenceType || (SequenceType = {}));
export var WidgetStackType;
(function (WidgetStackType) {
    WidgetStackType[WidgetStackType["Normal"] = 1] = "Normal";
    WidgetStackType[WidgetStackType["Full"] = 2] = "Full";
})(WidgetStackType || (WidgetStackType = {}));
export var LineType;
(function (LineType) {
    LineType[LineType["Smooth"] = 1] = "Smooth";
    LineType[LineType["Straight"] = 2] = "Straight";
    LineType[LineType["Stepline"] = 3] = "Stepline";
})(LineType || (LineType = {}));
export var ExportType;
(function (ExportType) {
    ExportType[ExportType["Excel"] = 1] = "Excel";
})(ExportType || (ExportType = {}));
export var MovingDirection;
(function (MovingDirection) {
    MovingDirection[MovingDirection["Left"] = 1] = "Left";
    MovingDirection[MovingDirection["Right"] = 2] = "Right";
})(MovingDirection || (MovingDirection = {}));
export var MapToolType;
(function (MapToolType) {
    MapToolType[MapToolType["Marker"] = 0] = "Marker";
    MapToolType[MapToolType["Circle"] = 1] = "Circle";
    MapToolType[MapToolType["Isochrones"] = 2] = "Isochrones";
    MapToolType[MapToolType["Areas"] = 3] = "Areas";
    MapToolType[MapToolType["DbLatLongs"] = 4] = "DbLatLongs";
    MapToolType[MapToolType["Amenities"] = 5] = "Amenities";
    MapToolType[MapToolType["CustomerData"] = 6] = "CustomerData";
})(MapToolType || (MapToolType = {}));
export var MapBwgType;
(function (MapBwgType) {
    MapBwgType[MapBwgType["Buurt"] = 0] = "Buurt";
    MapBwgType[MapBwgType["Wijk"] = 1] = "Wijk";
    MapBwgType[MapBwgType["Gemeente"] = 2] = "Gemeente";
})(MapBwgType || (MapBwgType = {}));
export var MapIsochroneProfile;
(function (MapIsochroneProfile) {
    MapIsochroneProfile[MapIsochroneProfile["Walking"] = 0] = "Walking";
    MapIsochroneProfile[MapIsochroneProfile["Driving"] = 1] = "Driving";
    MapIsochroneProfile[MapIsochroneProfile["Cycling"] = 2] = "Cycling";
})(MapIsochroneProfile || (MapIsochroneProfile = {}));
export var CustomerEventSource;
(function (CustomerEventSource) {
    CustomerEventSource[CustomerEventSource["System"] = 0] = "System";
    CustomerEventSource[CustomerEventSource["Custom"] = 1] = "Custom";
})(CustomerEventSource || (CustomerEventSource = {}));
export var CustomerType;
(function (CustomerType) {
    CustomerType[CustomerType["Private"] = 1] = "Private";
    CustomerType[CustomerType["Business"] = 2] = "Business";
})(CustomerType || (CustomerType = {}));
export var MailingType;
(function (MailingType) {
    MailingType[MailingType["Em"] = 0] = "Em";
    MailingType[MailingType["Dm"] = 1] = "Dm";
})(MailingType || (MailingType = {}));
/**
 * Represents the status of a review.
 */
export var ReviewStatus;
(function (ReviewStatus) {
    /**
     * Review has not yet been completed
     */
    ReviewStatus[ReviewStatus["Open"] = 0] = "Open";
    /**
     * Review has been accepted
     */
    ReviewStatus[ReviewStatus["Accepted"] = 1] = "Accepted";
    /**
     * Review has been rejected
     */
    ReviewStatus[ReviewStatus["Rejected"] = 2] = "Rejected";
    /**
     * Review request has been canceled
     */
    ReviewStatus[ReviewStatus["Canceled"] = 3] = "Canceled";
})(ReviewStatus || (ReviewStatus = {}));
export var JourneyStatus;
(function (JourneyStatus) {
    /**
     * Journey may be edited
     */
    JourneyStatus[JourneyStatus["Draft"] = 0] = "Draft";
    /**
     * Journey review is still pending, the minimum required reviewers haven't accepted the review yet
     */
    JourneyStatus[JourneyStatus["PendingReview"] = 1] = "PendingReview";
    /**
     * Journey has been reviewed
     */
    JourneyStatus[JourneyStatus["Reviewed"] = 2] = "Reviewed";
    /**
     * Journey is published but is not live
     */
    JourneyStatus[JourneyStatus["Published"] = 3] = "Published";
    /**
     * Journey is live, the journey is active and the current date falls between its start and end date
     */
    JourneyStatus[JourneyStatus["Live"] = 4] = "Live";
    /**
     * Journey is published, but has been marked as inactive preventing it from going live
     */
    JourneyStatus[JourneyStatus["Inactive"] = 5] = "Inactive";
})(JourneyStatus || (JourneyStatus = {}));
export var JourneyType;
(function (JourneyType) {
    JourneyType[JourneyType["Standard"] = 0] = "Standard";
    JourneyType[JourneyType["Umbrella"] = 1] = "Umbrella";
})(JourneyType || (JourneyType = {}));
export var JourneyBrickType;
(function (JourneyBrickType) {
    JourneyBrickType[JourneyBrickType["Audience"] = 1] = "Audience";
    JourneyBrickType[JourneyBrickType["Journey"] = 2] = "Journey";
    JourneyBrickType[JourneyBrickType["Touchpoint"] = 3] = "Touchpoint";
    JourneyBrickType[JourneyBrickType["AudienceFilter"] = 5] = "AudienceFilter";
    JourneyBrickType[JourneyBrickType["ABTest"] = 6] = "ABTest";
    JourneyBrickType[JourneyBrickType["Goal"] = 7] = "Goal";
})(JourneyBrickType || (JourneyBrickType = {}));
export var AppSessionMode;
(function (AppSessionMode) {
    AppSessionMode[AppSessionMode["View"] = 0] = "View";
    AppSessionMode[AppSessionMode["Edit"] = 1] = "Edit";
})(AppSessionMode || (AppSessionMode = {}));
export var ExpenseType;
(function (ExpenseType) {
    ExpenseType[ExpenseType["Unspecified"] = 0] = "Unspecified";
    ExpenseType[ExpenseType["Minutes"] = 2] = "Minutes";
    ExpenseType[ExpenseType["Questions"] = 4] = "Questions";
    ExpenseType[ExpenseType["Seats"] = 8] = "Seats";
    ExpenseType[ExpenseType["License"] = 10] = "License";
})(ExpenseType || (ExpenseType = {}));
export var Sentiment;
(function (Sentiment) {
    Sentiment[Sentiment["Neutral"] = 0] = "Neutral";
    Sentiment[Sentiment["Positive"] = 1] = "Positive";
    Sentiment[Sentiment["Negative"] = 2] = "Negative";
})(Sentiment || (Sentiment = {}));
export var QuestionScore;
(function (QuestionScore) {
    QuestionScore[QuestionScore["Unscored"] = 0] = "Unscored";
    QuestionScore[QuestionScore["Negative"] = 1] = "Negative";
    QuestionScore[QuestionScore["Positive"] = 10] = "Positive";
})(QuestionScore || (QuestionScore = {}));
export var ReportReason;
(function (ReportReason) {
    ReportReason[ReportReason["BadAudio"] = 0] = "BadAudio";
    ReportReason[ReportReason["IncompleteTranscript"] = 1] = "IncompleteTranscript";
    ReportReason[ReportReason["Other"] = 2] = "Other";
})(ReportReason || (ReportReason = {}));
export var ResultCode;
(function (ResultCode) {
    ResultCode[ResultCode["Ok"] = 0] = "Ok";
    ResultCode[ResultCode["NotFound"] = 1] = "NotFound";
    ResultCode[ResultCode["InternalServerError"] = 2] = "InternalServerError";
    ResultCode[ResultCode["Unauthorized"] = 3] = "Unauthorized";
    ResultCode[ResultCode["ValidationError"] = 4] = "ValidationError";
    ResultCode[ResultCode["Unknown"] = 5] = "Unknown";
    ResultCode[ResultCode["Created"] = 6] = "Created";
    ResultCode[ResultCode["Forbidden"] = 7] = "Forbidden";
    ResultCode[ResultCode["HealthError"] = 8] = "HealthError";
})(ResultCode || (ResultCode = {}));
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["Unknown"] = 0] = "Unknown";
    ErrorCode[ErrorCode["MultipleErrorCodes"] = 1] = "MultipleErrorCodes";
    ErrorCode[ErrorCode["CurrentUserNotFound"] = 2] = "CurrentUserNotFound";
    ErrorCode[ErrorCode["FailedToSaveChanges"] = 3] = "FailedToSaveChanges";
    ErrorCode[ErrorCode["CacheEntryNotFound"] = 4] = "CacheEntryNotFound";
    ErrorCode[ErrorCode["CreateFailed"] = 5] = "CreateFailed";
    ErrorCode[ErrorCode["DeleteFailed"] = 6] = "DeleteFailed";
    ErrorCode[ErrorCode["UpdateFailed"] = 7] = "UpdateFailed";
    ErrorCode[ErrorCode["DashboardForbidden"] = 10] = "DashboardForbidden";
    ErrorCode[ErrorCode["DashboardNotFound"] = 11] = "DashboardNotFound";
    ErrorCode[ErrorCode["NoVisibleDashboardsForUser"] = 12] = "NoVisibleDashboardsForUser";
    ErrorCode[ErrorCode["DashboardMustContainWidgetGroups"] = 13] = "DashboardMustContainWidgetGroups";
    ErrorCode[ErrorCode["DashboardFilterNotFound"] = 14] = "DashboardFilterNotFound";
    ErrorCode[ErrorCode["NullsInWidgetData"] = 15] = "NullsInWidgetData";
    ErrorCode[ErrorCode["FailedToSetDashboardUsers"] = 16] = "FailedToSetDashboardUsers";
    ErrorCode[ErrorCode["DivisionNotFound"] = 21] = "DivisionNotFound";
    ErrorCode[ErrorCode["DivisionForbidden"] = 22] = "DivisionForbidden";
    ErrorCode[ErrorCode["InvalidRootFolderPath"] = 29] = "InvalidRootFolderPath";
    ErrorCode[ErrorCode["UserHomeShareCanBeOwnedByOnlyOneUser"] = 30] = "UserHomeShareCanBeOwnedByOnlyOneUser";
    ErrorCode[ErrorCode["FileShareNotFound"] = 31] = "FileShareNotFound";
    ErrorCode[ErrorCode["UserCanNotReadThisFileShare"] = 32] = "UserCanNotReadThisFileShare";
    ErrorCode[ErrorCode["UserCanNotWriteToThisFileShare"] = 33] = "UserCanNotWriteToThisFileShare";
    ErrorCode[ErrorCode["UserCanNotViewSubfoldersInThisFileShare"] = 34] = "UserCanNotViewSubfoldersInThisFileShare";
    ErrorCode[ErrorCode["UserCanNotCreateSubfoldersInThisFileShare"] = 35] = "UserCanNotCreateSubfoldersInThisFileShare";
    ErrorCode[ErrorCode["InvalidFilePath"] = 36] = "InvalidFilePath";
    ErrorCode[ErrorCode["FilesExceedMaxSize"] = 37] = "FilesExceedMaxSize";
    ErrorCode[ErrorCode["NotAllFilesAreAllowed"] = 38] = "NotAllFilesAreAllowed";
    ErrorCode[ErrorCode["PathOutsideOfShare"] = 39] = "PathOutsideOfShare";
    ErrorCode[ErrorCode["FileNotFound"] = 40] = "FileNotFound";
    ErrorCode[ErrorCode["UserNotFound"] = 41] = "UserNotFound";
    ErrorCode[ErrorCode["InvalidEmail"] = 42] = "InvalidEmail";
    ErrorCode[ErrorCode["PendingChangePasswordRequest"] = 43] = "PendingChangePasswordRequest";
    ErrorCode[ErrorCode["PasswordNotAllowedByPolicy"] = 44] = "PasswordNotAllowedByPolicy";
    ErrorCode[ErrorCode["TokenExpired"] = 45] = "TokenExpired";
    ErrorCode[ErrorCode["NoReadPermission"] = 46] = "NoReadPermission";
    ErrorCode[ErrorCode["NoWritePermission"] = 47] = "NoWritePermission";
    ErrorCode[ErrorCode["NoCreatePermission"] = 48] = "NoCreatePermission";
    ErrorCode[ErrorCode["NoDeletePermission"] = 49] = "NoDeletePermission";
    ErrorCode[ErrorCode["MessageTemplateNotFound"] = 51] = "MessageTemplateNotFound";
    ErrorCode[ErrorCode["InvalidMessageType"] = 52] = "InvalidMessageType";
    ErrorCode[ErrorCode["MessageMustHaveMessageTransportId"] = 53] = "MessageMustHaveMessageTransportId";
    ErrorCode[ErrorCode["WidgetMustBeInWidgetGroup"] = 61] = "WidgetMustBeInWidgetGroup";
    ErrorCode[ErrorCode["WidgetNotFound"] = 62] = "WidgetNotFound";
    ErrorCode[ErrorCode["ColorThemeNotFound"] = 63] = "ColorThemeNotFound";
    ErrorCode[ErrorCode["ColorThemeUsedByWidgetCannotBeDeleted"] = 64] = "ColorThemeUsedByWidgetCannotBeDeleted";
    ErrorCode[ErrorCode["WidgetIsAlreadyOnEdgePosition"] = 65] = "WidgetIsAlreadyOnEdgePosition";
    ErrorCode[ErrorCode["WidgetGroupNotFound"] = 66] = "WidgetGroupNotFound";
    ErrorCode[ErrorCode["WidgetTablePropertyNamesMustNotContainDuplicates"] = 67] = "WidgetTablePropertyNamesMustNotContainDuplicates";
    ErrorCode[ErrorCode["WidgetGraphDataMustBeNumeric"] = 68] = "WidgetGraphDataMustBeNumeric";
    ErrorCode[ErrorCode["WidgetGraphDataMustHaveAtLeastTwoColumns"] = 69] = "WidgetGraphDataMustHaveAtLeastTwoColumns";
    ErrorCode[ErrorCode["RoleNotFound"] = 70] = "RoleNotFound";
    ErrorCode[ErrorCode["LocaleNotFound"] = 71] = "LocaleNotFound";
    ErrorCode[ErrorCode["InvalidLocale"] = 72] = "InvalidLocale";
    ErrorCode[ErrorCode["TranslationSetNotFound"] = 73] = "TranslationSetNotFound";
    ErrorCode[ErrorCode["DnxTaskNotFound"] = 80] = "DnxTaskNotFound";
    ErrorCode[ErrorCode["TypeNameForDnxTaskNotFound"] = 81] = "TypeNameForDnxTaskNotFound";
    ErrorCode[ErrorCode["FailedToGetTaskInstance"] = 82] = "FailedToGetTaskInstance";
    ErrorCode[ErrorCode["ApiTargetNotFound"] = 90] = "ApiTargetNotFound";
    ErrorCode[ErrorCode["UserLockedOut"] = 91] = "UserLockedOut";
    ErrorCode[ErrorCode["InvalidTotpCode"] = 92] = "InvalidTotpCode";
    ErrorCode[ErrorCode["DnxMapNotFound"] = 93] = "DnxMapNotFound";
    ErrorCode[ErrorCode["DnxMapProjectNotFound"] = 94] = "DnxMapProjectNotFound";
    ErrorCode[ErrorCode["DnxMapToolOptionNotFound"] = 95] = "DnxMapToolOptionNotFound";
    ErrorCode[ErrorCode["ModifyMapNotAllowed"] = 96] = "ModifyMapNotAllowed";
    ErrorCode[ErrorCode["AccessMapProjectNotAllowed"] = 97] = "AccessMapProjectNotAllowed";
    ErrorCode[ErrorCode["DnxMapGeoApiFailure"] = 98] = "DnxMapGeoApiFailure";
    ErrorCode[ErrorCode["CanNotEditAnotherUsersAccount"] = 99] = "CanNotEditAnotherUsersAccount";
    ErrorCode[ErrorCode["DnxMapNoMapReadPermission"] = 100] = "DnxMapNoMapReadPermission";
    ErrorCode[ErrorCode["DnxMapNoMapCreatePermission"] = 101] = "DnxMapNoMapCreatePermission";
    ErrorCode[ErrorCode["DnxMapNoMapUpdatePermission"] = 102] = "DnxMapNoMapUpdatePermission";
    ErrorCode[ErrorCode["DnxMapNoMapDeletePermission"] = 103] = "DnxMapNoMapDeletePermission";
    ErrorCode[ErrorCode["SnowplowTrackerNotFound"] = 110] = "SnowplowTrackerNotFound";
    ErrorCode[ErrorCode["DataEntityNotFound"] = 111] = "DataEntityNotFound";
    ErrorCode[ErrorCode["ValidationGeneric"] = 112] = "ValidationGeneric";
    ErrorCode[ErrorCode["ValidationEmailRequired"] = 113] = "ValidationEmailRequired";
    ErrorCode[ErrorCode["ValidationFirstNameLength"] = 114] = "ValidationFirstNameLength";
    ErrorCode[ErrorCode["ValidationPrefixLength"] = 115] = "ValidationPrefixLength";
    ErrorCode[ErrorCode["ValidationLastNameLength"] = 116] = "ValidationLastNameLength";
    ErrorCode[ErrorCode["ValidationFunctionLength"] = 117] = "ValidationFunctionLength";
    ErrorCode[ErrorCode["ValidationEmailLength"] = 118] = "ValidationEmailLength";
    ErrorCode[ErrorCode["ValidationPhoneLength"] = 119] = "ValidationPhoneLength";
    ErrorCode[ErrorCode["ValidationPhoneMobileLength"] = 120] = "ValidationPhoneMobileLength";
    ErrorCode[ErrorCode["ValidationRoleIdsRequired"] = 121] = "ValidationRoleIdsRequired";
    ErrorCode[ErrorCode["ValidationDashboardIdsRequired"] = 122] = "ValidationDashboardIdsRequired";
    ErrorCode[ErrorCode["ValidationPasswordConfirmDoesNotMatch"] = 123] = "ValidationPasswordConfirmDoesNotMatch";
    ErrorCode[ErrorCode["ValidationDivisionIdRequired"] = 124] = "ValidationDivisionIdRequired";
    ErrorCode[ErrorCode["IncorrectPassword"] = 125] = "IncorrectPassword";
    ErrorCode[ErrorCode["ValidationNewPasswordMustBeDifferent"] = 126] = "ValidationNewPasswordMustBeDifferent";
    ErrorCode[ErrorCode["InvalidPhoneNumber"] = 127] = "InvalidPhoneNumber";
    ErrorCode[ErrorCode["JourneyForbidden"] = 130] = "JourneyForbidden";
    ErrorCode[ErrorCode["JourneyNotFound"] = 131] = "JourneyNotFound";
    ErrorCode[ErrorCode["JourneyCannotBeChangedWhenNotDraft"] = 132] = "JourneyCannotBeChangedWhenNotDraft";
    ErrorCode[ErrorCode["JourneyWithJourneyBrickCannotBeDeleted"] = 133] = "JourneyWithJourneyBrickCannotBeDeleted";
    ErrorCode[ErrorCode["JourneyBrickNotFound"] = 134] = "JourneyBrickNotFound";
    ErrorCode[ErrorCode["JourneyBrickConnectionNotFound"] = 135] = "JourneyBrickConnectionNotFound";
    ErrorCode[ErrorCode["JourneyHasOpenDraft"] = 136] = "JourneyHasOpenDraft";
    ErrorCode[ErrorCode["CustomerNotFound"] = 140] = "CustomerNotFound";
    ErrorCode[ErrorCode["ContactNotFound"] = 141] = "ContactNotFound";
    ErrorCode[ErrorCode["RoleGroupNotFound"] = 150] = "RoleGroupNotFound";
    ErrorCode[ErrorCode["ChangePasswordTokenInvalid"] = 151] = "ChangePasswordTokenInvalid";
    ErrorCode[ErrorCode["ReviewAlreadyRequested"] = 152] = "ReviewAlreadyRequested";
    ErrorCode[ErrorCode["NotEnoughReviewers"] = 153] = "NotEnoughReviewers";
    ErrorCode[ErrorCode["ReviewNotFound"] = 154] = "ReviewNotFound";
    ErrorCode[ErrorCode["ReviewRequestNotFound"] = 155] = "ReviewRequestNotFound";
    ErrorCode[ErrorCode["ReviewRequestClosed"] = 156] = "ReviewRequestClosed";
    ErrorCode[ErrorCode["JourneyNotReviewed"] = 157] = "JourneyNotReviewed";
    ErrorCode[ErrorCode["PlatformThemeNotFound"] = 158] = "PlatformThemeNotFound";
    ErrorCode[ErrorCode["ChangePasswordValidationCodeInvalid"] = 159] = "ChangePasswordValidationCodeInvalid";
    ErrorCode[ErrorCode["PlatformThemeVariantsIncomplete"] = 160] = "PlatformThemeVariantsIncomplete";
    ErrorCode[ErrorCode["ValidationEmailAlreadyInUse"] = 161] = "ValidationEmailAlreadyInUse";
    ErrorCode[ErrorCode["MailingIdRequired"] = 162] = "MailingIdRequired";
    ErrorCode[ErrorCode["MailingTemplateRequired"] = 163] = "MailingTemplateRequired";
    ErrorCode[ErrorCode["MailingNameNotUnique"] = 164] = "MailingNameNotUnique";
    ErrorCode[ErrorCode["RecordingMetadataNotFound"] = 165] = "RecordingMetadataNotFound";
    ErrorCode[ErrorCode["RecordingMetadataAlreadyExists"] = 166] = "RecordingMetadataAlreadyExists";
    ErrorCode[ErrorCode["RecordingNotFound"] = 167] = "RecordingNotFound";
    ErrorCode[ErrorCode["ManglQaQuestionNotFound"] = 168] = "ManglQaQuestionNotFound";
})(ErrorCode || (ErrorCode = {}));
