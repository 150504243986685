var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useLoginToken from './useLoginToken';
import { processResponse } from '../api/helpers';
export default class ApiRequestHandler {
    constructor() { }
    getUnauthorizedRequestOptions() {
        return __awaiter(this, arguments, void 0, function* (method = 'GET') {
            return {
                method,
                headers: {},
            };
        });
    }
    getAuthorizedRequestOptions() {
        return __awaiter(this, arguments, void 0, function* (method = 'GET', mytoken) {
            let { token } = useLoginToken();
            token !== null && token !== void 0 ? token : (token = mytoken);
            return {
                method,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
        });
    }
    /**
     * Call fetch with the provided parameters and return the Reponse promise
     * @param {string} path
     * @param {RequestInit} request
     * @return {Promise<Response>}
     */
    fetch(path, request) {
        // Calls in auth are made before store is accessible!
        const dontCache = (path, request) => {
            var _a, _b;
            // defined in app-full
            if (!((_b = (_a = Window.runtime) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.fetch))
                return true;
            // Create calls go to api/widgets!
            if (path.indexOf('widgets/') < 0 && path.indexOf('dashboard') < 0)
                return true;
            if (request.method !== 'GET' && request.method !== 'POST')
                return true;
            return false;
        };
        return dontCache(path, request)
            ? fetch(path, request)
            : Window.runtime.query.fetch(path, request);
        return fetch(path, request);
    }
    /**
     * Parses the Response object and returns a DnxResponse
     * @param {Response} response
     * @return {DnxResponse}
     */
    handleResponse(response) {
        return processResponse(response);
    }
}
const requestHandler = new ApiRequestHandler();
export { requestHandler };
