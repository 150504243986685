import { T } from '@dnx/core';
const tourT = (translationKey) => {
    return T(translationKey).split('\n').join('<br/><br/>');
};
export const getDashboardsTourSteps = () => [
    {
        id: 'a665a3ff-f0b7-4441-8ef5-091181e8bcaa',
        popover: {
            title: tourT('MANGL_DASHBOARDS_TOUR_OVERVIEW_TITLE'),
            description: tourT('MANGL_DASHBOARDS_TOUR_OVERVIEW_TEXT'),
        },
    },
    {
        id: '9136a9f2-25d2-4061-b4ba-7d3c54d630cd',
        popover: {
            title: tourT('MANGL_DASHBOARDS_TOUR_AGENTS_TITLE'),
            description: tourT('MANGL_DASHBOARDS_TOUR_AGENTS_TEXT'),
        },
    },
    {
        id: '09f21fe7-47d0-4d14-9720-d51cf958abd9',
        popover: {
            title: tourT('MANGL_DASHBOARDS_TOUR_FLAGS_TITLE'),
            description: tourT('MANGL_DASHBOARDS_TOUR_FLAGS_TEXT'),
        },
    },
    {
        id: 'd9ce852e-25d9-4d7c-8802-f5daf0ba5f7a',
        popover: {
            title: tourT('MANGL_DASHBOARDS_TOUR_ANALYSIS_TITLE'),
            description: tourT('MANGL_DASHBOARDS_TOUR_ANALYSIS_TEXT'),
        },
    },
];
