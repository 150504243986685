import {createWebHistory, createRouter} from 'vue-router';
import Overview from './pages/Overview.vue';

import {RouteRecordRaw} from 'vue-router';

import {addToast, T} from '@dnx/core';

import dashboardApi from '../app/api-generated/dashboards';

// export const routes: RouteRecordRaw[] = ;

export const routes = [
  {
    name: 'dashboards-overview',
    path: '/',
    component: () => import('./pages/Overview.vue'),
    meta: {
      translationKey: 'ROUTE_DASHBOARD_OVERVIEW',
    },
  },
  {
    name: 'dashboard',
    path: '/:dashboard_id/:group_id?',
    component: () => import('./pages/Dashboard.vue'),
    meta: {
      translationKey: 'ROUTE_DASHBOARD',
    },
    props: true,
    beforeEnter: async (to, from, next) => {
      const dashboard_id = to.params.dashboard_id;
      const group_id = to.params.group_id;
      const response = await dashboardApi.get(dashboard_id);
      if (response.value.error) {
        addToast('error', T(response.value.message));
        next({name: 'dashboards-overview'});
        return;
      }
      if (group_id) {
        next();
        return;
      } else {
        //Get first widgetGroup
        const widgetId = response.value.widgetGroups[0]?.id;
        if (!widgetId) {
          next();
          return;
        }
        next({path: `/dashboards/${dashboard_id}/${widgetId}`});
      }
    },
  },
  {
    name: 'create-dashboard',
    path: '/create/:dashboard_id/:group_id?',
    component: () => import('./pages/CreateDashboard.vue'),
    meta: {
      confirmLeave: true,
      permissionId: 'dashboards',
    },
    props: true,
  },
  {
    name: 'create-widget',
    path: '/:dashboard_id/:group_id/create/:widget_id',

    component: () => import('./pages/CreateWidget.vue'),
    meta: {
      translationKey: 'ROUTE_CREATE_WIDGET',
      confirmLeave: true,
      enforceConfirmLeave: true,
      permissionId: 'dashboards',
    },
    props: true,
  },
  {
    name: 'edit-widget',
    path: '/:dashboard_id/:group_id/edit/:widget_id?',
    component: () => import('./pages/CreateWidget.vue'),
    meta: {
      translationKey: 'ROUTE_EDIT_WIDGET',
      confirmLeave: true,
      permissionId: 'dashboards',
    },
    props: true,
  },
  {
    name: 'widget-themes',
    path: '/themes',
    alias: ['/themes/:id'],
    component: () => import('./pages/WidgetThemesOverview.vue'),
    meta: {
      translationKey: 'ROUTE_WIDGET_THEME',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
