var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useLoginToken from '../utils/useLoginToken';
import { requestHandler } from "../utils/apirequesthandler";
import { useServerMessagesService } from '@dnx/core';
//DEV: Keep this file in sync with app-full/api-generated!
const withAuthorization = (options) => {
    const { token } = useLoginToken();
    options = options || {};
    //Only modify our options when a token is actually present
    if (token) {
        //Ensure our options contains a headers object
        if (!options.headers) {
            options.headers = {};
        }
        //Add our token as bearer
        options.headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    return options;
};
const processResponse = (response) => __awaiter(void 0, void 0, void 0, function* () {
    // if(response.status === 403) {
    //     alert("You are not authorized to access this resource.");
    // }
    const appSessionStatus = response.headers.get('x-appsession-status');
    if (appSessionStatus == 'readonly') {
        Window.runtime.store.dispatch('notifications/triggerAlertMessage', {
            type: 'warning',
            message: 'Already edited by another user' // T('APPSESSION_ALREADY_EDITED'),
        });
    }
    return yield DnxResponse.Parse(response);
});
class DnxResponse {
    constructor() {
        //Initialize with impossible values, constructor is not to be invoked directly
        this.success = false;
        this.statusCode = -1;
        this.message = null;
        this.value = null;
        this.headers = {};
    }
    /**
     * Parse a response object to a DNX Response
     * @param {Response} response Fetch response
     * @return {Promise<DnxResponse>} Parsed DNX Response
     * @constructor
     */
    static Parse(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const dnxResponse = new DnxResponse();
            dnxResponse.value = (yield DnxResponse._readContent(response));
            dnxResponse.success = response.ok;
            dnxResponse.statusCode = response.status;
            dnxResponse.message = response.statusText;
            response.headers.forEach((value, key) => {
                dnxResponse.headers[key] = value;
            });
            // can crash in certain rare scenarios due to the wrong pinia/pinia being accessed too early
            try {
                if (dnxResponse.headers['x-notifications-latestnotificationtimestamp']) {
                    const serverMessages = useServerMessagesService();
                    serverMessages.setTicket(dnxResponse.headers['x-notifications-latestnotificationtimestamp']);
                }
            }
            catch (e) { }
            if (dnxResponse.headers['x-paginginfo-pagecount']) {
                dnxResponse.pagingInfo = {
                    itemCount: Number(dnxResponse.headers['x-paginginfo-itemcount']),
                    totalItemCount: Number(dnxResponse.headers['x-paginginfo-totalitemcount']),
                    pageCount: Number(dnxResponse.headers['x-paginginfo-pagecount']),
                    pageNumber: Number(dnxResponse.headers['x-paginginfo-pagenumber']),
                    pageSize: Number(dnxResponse.headers['x-paginginfo-pagesize']),
                };
            }
            return dnxResponse;
        });
    }
    /**
     * Read the content of the given response
     * @param {Response} response
     * @return {Promise<Object|string>}
     * @private
     */
    static _readContent(response) {
        return __awaiter(this, void 0, void 0, function* () {
            //Check if our content is JSON
            //when in doubt; assume text
            const contentTypeHeader = response.headers.get('content-type');
            if (contentTypeHeader &&
                (contentTypeHeader.indexOf('application/json') !== -1 ||
                    contentTypeHeader.indexOf('application/problem+json') !== -1)) {
                return (yield response.json());
            }
            else {
                return yield response.text();
            }
        });
    }
}
export { DnxResponse, withAuthorization, processResponse, requestHandler };
