export class Contract {
    constructor() {
        this.reset();
    }
    reset() {
        this.awaitable = new Promise((resolve, reject) => {
            this.resolveCallback = resolve;
            this.rejectCallback = reject;
        });
    }
    resolve(payload) {
        if (this.resolveCallback) {
            this.resolveCallback(payload);
            this.resolveCallback = null;
            this.rejectCallback = null;
        }
    }
    reject(reason) {
        if (this.rejectCallback) {
            this.rejectCallback(reason);
            this.resolveCallback = null;
            this.rejectCallback = null;
        }
    }
}
