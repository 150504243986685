var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createResetMutation } from '@dnx/core';
import dashboards from '../../app/api-generated/dashboards';
import { FilterManager } from '../components/querybuilder/filter';
import { $can } from '@dnx/core';
const GET_INITIAL_STATE = () => ({
    /**
     * @type {Dashboard[]}
     */
    dateRangeOptions: null,
    allDashboards: null,
    assignableDashboards: [],
    selectedDashboard: null,
    dashboard: null,
    groupsList: null,
    selectedGroupId: null,
    selectedGroupWidgets: [],
    dateRangeFilter: null,
    dashboardFilterPossibleValues: {},
    usersLinkedToDashboard: [],
    pendingDownloads: [],
});
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    getters: {
        getUsersLinkedToDashboard: state => state.usersLinkedToDashboard,
        getDateRangeOptions: state => state.dateRangeOptions,
        getEditRights: () => $can.write.dashboards,
        getAllDashboards: state => state.allDashboards,
        getAssignableDashboards: state => state.assignableDashboards,
        getSelectedDashboard: state => state.selectedDashboard,
        getDashboard: state => state.dashboard,
        getGroupsList: state => state.groupsList,
        getSelectedGroupId: state => state.selectedGroupId,
        getSelectedGroupWidgets: state => state.selectedGroupWidgets,
        getDateRangeFilter: state => {
            //Determine our daterange in order of importance
            //* User configured
            //* Dashboard configured
            //Has a filter been manually configured?
            if (state.dateRangeFilter) {
                return state.dateRangeFilter;
            }
            //Has a dashboard wide filter been configured?
            if (state.dashboard && state.dashboard.defaultDateRange) {
                // extracting the date only without the time
                const startDate = state.dashboard.defaultDateRange.startDate.substr(0, 10);
                const endDate = state.dashboard.defaultDateRange.endDate.substr(0, 10);
                // if the user hasn't selected time, we want the default to always point to midnight instead of local time.
                let startDateObj = new Date(Date.parse(startDate));
                let endDateObj = new Date(Date.parse(endDate));
                startDateObj = new Date(startDateObj.getFullYear(), startDateObj.getMonth(), startDateObj.getDate()).toISOString();
                endDateObj = new Date(endDateObj.getFullYear(), endDateObj.getMonth(), endDateObj.getDate()).toISOString();
                return { startDate: startDateObj, endDate: endDateObj };
            }
            //Let our backend determine a range
            return {
                startDate: undefined,
                endDate: undefined,
            };
        },
        getPendingDownloads: state => state.pendingDownloads,
        getDashboardFilterPossibleValues: state => state.dashboardFilterPossibleValues,
        /**
         * Search the current dashboard for the widget with the given id
         * @param state
         * @return {function(*): *} Widget lookup
         */
        getWidget: state => id => { var _a; return (_a = state.dashboard) === null || _a === void 0 ? void 0 : _a.widgetGroups.flatMap(group => group.widgets).find(widget => widget.id === id); },
    },
    actions: {
        fetchUsersInDashboard(_a, dashboardId_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, dashboardId) {
                const response = yield dashboards.getUsers(dashboardId);
                commit('setUsersLinkedToDashboard', response.value);
            });
        },
        // eslint-disable-next-line no-empty-pattern
        updateUsers(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({}, data) {
                yield dashboards.addUsersToDashboard(data.id, data.userIds);
            });
        },
        triggerPdfExport(_a) {
            return __awaiter(this, arguments, void 0, function* ({ state, getters, rootGetters }) {
                const dashboardId = state.selectedDashboard;
                const filters = FilterManager.filtersWithValues.map(f => f.serializeToDto());
                if (FilterManager.startDate)
                    filters.push({ key: 'start', value: FilterManager.startDate });
                if (FilterManager.endDate)
                    filters.push({ key: 'end', value: FilterManager.endDate });
                return yield dashboards.export(dashboardId, FilterManager.divisionId, filters);
            });
        },
        fetchAllDashboards(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield dashboards.getVisibleDashboards();
                commit('setAllDashboards', response.value);
            });
        },
        fetchAssignableDashboards(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield dashboards.getAssignableDashboards();
                commit('setAssignableDashboards', response.value);
            });
        },
        fetchDateRangeOptions(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield dashboards.getDateRangeOptions();
                commit('setDateRangeOptions', response.value);
            });
        },
        fetchDashboard(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, data) {
                var _b, _c;
                if (!data) {
                    console.warn('No data provided to fetchDashboard call, this was likely a mistake, call ignored');
                    return;
                }
                const response = yield dashboards.get(data);
                if (response.success) {
                    commit('setDashboard', response.value);
                }
                else {
                    throw new Error((_c = (_b = response.value) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : response.message);
                }
            });
        },
        createDashboard(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, data) {
                const response = yield dashboards.create(data);
                commit('setDashboard', response.value);
            });
        },
        updateDashboard(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, data) {
                const response = yield dashboards.edit(data);
                commit('setDashboard', response.value);
            });
        },
        deleteDashboard(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit, dispatch }, data) {
                const response = yield dashboards.delete(data);
                yield dispatch('fetchAllDashboards');
                commit('setDashboard', response.value);
                return response;
            });
        },
        fetchDashboardFilterPossibleValues(_a, data_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, data) {
                const response = yield dashboards.getDashboardFilterValues(data);
                commit('setDashboardFilterPossibleValues', {
                    data: response.value,
                    id: data,
                });
            });
        },
        clearDashboard({ commit }) {
            commit('setDashboard', null);
        },
        swapWidgets({ commit }, data) {
            commit('setSwappedWidgets', data);
        },
    },
    mutations: {
        reset: state => createResetMutation(Object.assign(Object.assign({}, GET_INITIAL_STATE()), { pendingDownloads: state.pendingDownloads }))(state),
        setUsersLinkedToDashboard: (state, data) => (state.usersLinkedToDashboard = data),
        setDateRangeOptions: (state, data) => (state.dateRangeOptions = data),
        setAllDashboards: (state, data) => (state.allDashboards = data),
        setAssignableDashboards: (state, data) => (state.assignableDashboards = data),
        setSelectedDashboard(state, data) {
            // Reset the state if the dropdown placeholder is selected
            if (data === null) {
                state.selectedDashboard = null;
                state.dashboard = null;
                state.groupsList = null;
                state.selectedGroupId = null;
            }
            else {
                state.selectedDashboard = data;
            }
        },
        setPendingDownloads: (state, data) => (state.pendingDownloads = data),
        setWidgetProperty(state, data) {
            const widgetsInGroup = state.dashboard.widgetGroups.find(group => group.id === data.groupId).widgets;
            const selectedWidget = widgetsInGroup.find(widget => widget.id === data.widgetId);
            selectedWidget[data.propertyName] = data.propertyValue;
        },
        setDashboard: (state, data) => (state.dashboard = data),
        setGroupsList: (state, data) => (state.groupsList = data),
        setSelectedGroupId: (state, data) => {
            var _a, _b;
            state.selectedGroupId = data;
            if (!state.dashboard) {
                state.selectedGroupWidgets = [];
            }
            const selectedGroup = (_a = state.dashboard) === null || _a === void 0 ? void 0 : _a.widgetGroups.find(wG => wG.id === state.selectedGroupId);
            state.selectedGroupWidgets = (_b = selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.widgets) !== null && _b !== void 0 ? _b : [];
        },
        setSwappedWidgets(state, data) {
            const widgetsInGroup = state.dashboard.widgetGroups.find(group => group.id === data.groupId).widgets;
            [widgetsInGroup[data.oldIndex], widgetsInGroup[data.newIndex]] = [
                widgetsInGroup[data.newIndex],
                widgetsInGroup[data.oldIndex],
            ];
            state.dashboard.widgetGroups.map(group => {
                if (group.id === data.groupId) {
                    return (group.widgets = widgetsInGroup);
                }
                return group;
            });
        },
        setDateRangeFilter: (state, data) => (state.dateRangeFilter = data),
        setDashboardFilterPossibleValues(state, data) {
            state.dashboardFilterPossibleValues[data.id] = data.data;
        },
    },
};
