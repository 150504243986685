import { OverviewProvider, T, $can } from '@dnx/core';
export function wire() {
    const moduleName = 'dashboarding';
    OverviewProvider.registerModuleConfiguration(moduleName, {
        badge: { name: 'graph-pie', color: 'var(--module-dashboarding, #2783C6)' },
        searchableProperties: ['name'],
        placeholderImage: '/assets/dashboarding-placeholder.png',
        get topBarButtons() {
            if (!$can.create.dashboards)
                return [];
            const buttons = [{
                    link: '/dashboards/themes',
                    variant: 'outlined',
                    icon: { name: 'theme' },
                    get label() {
                        return T('THEMES');
                    },
                    name: 'ghost',
                }];
            if ($can.create.widgetthemes) {
                buttons.push({
                    link: '/dashboards/create/0',
                    color: 'success',
                    icon: { name: 'plus-square' },
                    get label() {
                        return T('DASHBOARD_CREATE_DASHBOARD');
                    },
                    name: 'create',
                });
            }
            return buttons;
        }
    });
    // enkele callback registreren voor module
    OverviewProvider.registerRecordConfigurationProvider(moduleName, (dashboard) => {
        const contextMenuOptions = [];
        if ($can.write.dashboards) {
            contextMenuOptions.push({
                link: `/dashboards/create/${dashboard.id}`,
                icon: { name: 'gear' },
                label: T('DASHBOARD_SETTINGS_BUTTON'),
            });
        }
        return {
            editRoute: `/dashboards/${dashboard.id}`,
            description: `${dashboard.widgetCount} ${T('DASHBOARD_WIDGETS')}`,
            contextMenuOptions: contextMenuOptions,
        };
    });
}
