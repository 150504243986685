import { GraphType } from '../typedefs/dashboards';
export const widgetOption = {
    implementedGraph: [
        GraphType.bar,
        GraphType.pie,
        GraphType.mixed,
        GraphType.bubble,
        GraphType.area,
        GraphType.table,
        GraphType.line,
        GraphType.gauge,
        GraphType.graph,
        GraphType.scatterPlot,
        GraphType.map,
        GraphType.brush,
        GraphType.dynamicLoaded,
        GraphType.radar,
        GraphType.number,
        GraphType.wordCloud,
    ],
    themeColors: [
        GraphType.bar,
        GraphType.pie,
        GraphType.mixed,
        GraphType.bubble,
        GraphType.area,
        GraphType.line,
        GraphType.scatterPlot,
        GraphType.brush,
        GraphType.dynamicLoaded,
        GraphType.radar,
        GraphType.wordCloud,
    ],
    dataIsNotSeries: [GraphType.map],
    xaxisType: [
        GraphType.bar,
        GraphType.mixed,
        GraphType.bubble,
        GraphType.area,
        GraphType.line,
        GraphType.scatterPlot,
        GraphType.brush,
        GraphType.dynamicLoaded,
        GraphType.radar,
    ],
    stacked: [GraphType.bar, GraphType.mixed, GraphType.area, GraphType.line],
    lineType: [GraphType.mixed, GraphType.area, GraphType.line, GraphType.brush],
    dataLabels: [
        GraphType.bar,
        GraphType.pie,
        GraphType.mixed,
        GraphType.bubble,
        GraphType.area,
        GraphType.line,
        GraphType.gauge,
        GraphType.scatterPlot,
        GraphType.brush,
        GraphType.dynamicLoaded,
        GraphType.radar,
    ],
    zoom: [GraphType.bar, GraphType.mixed, GraphType.area, GraphType.line, GraphType.brush, GraphType.dynamicLoaded],
    excludeFromSqlEditor: [GraphType.graph, GraphType.map],
    excludeFromColumnSettings: [GraphType.graph, GraphType.map],
    excludeFromAddingSequence: [GraphType.map, GraphType.gauge, GraphType.pie, GraphType.number, GraphType.wordCloud],
    visibleSeriesCommonDataType: [
        GraphType.bar,
        GraphType.area,
        GraphType.line,
        GraphType.bubble,
        GraphType.scatterPlot,
        GraphType.brush,
        GraphType.dynamicLoaded,
        GraphType.radar,
    ],
    forecast: [GraphType.bar, GraphType.line, GraphType.area, GraphType.mixed, GraphType.brush],
    isForecastDataAvailable(xAxisType, data) {
        if (xAxisType == 'datetime' || xAxisType == 'numeric') {
            return true;
        }
        // Pay extra attention to “day of the week“, “Month name“ etc, they are of type date, but might be that now they look like “categories“
        if (xAxisType == 'category' && data) {
            const series = Array.isArray(data) ? data : data.series ? data.series : undefined;
            if (!series)
                return false;
            const checkForForecastPointsAndDates = series.find((item) => {
                var _a;
                return item.forecastCount > 0 && !isNaN(Date.parse(String(new Date((_a = item.data[0]) === null || _a === void 0 ? void 0 : _a.x))));
            });
            return !!checkForForecastPointsAndDates;
        }
        return false;
    },
    refresh: [GraphType.line, GraphType.area],
    realtime: [GraphType.line],
    annotations: [GraphType.line],
    minimumWidth: {
        [GraphType.bar]: 3,
        [GraphType.pie]: 2,
        [GraphType.mixed]: 3,
        [GraphType.bubble]: 3,
        [GraphType.area]: 3,
        [GraphType.table]: 4,
        [GraphType.line]: 3,
        [GraphType.gauge]: 2,
        [GraphType.graph]: 4,
        [GraphType.scatterPlot]: 3,
        [GraphType.map]: 4,
        [GraphType.brush]: 3,
        [GraphType.dynamicLoaded]: 3,
        [GraphType.radar]: 3,
        [GraphType.number]: 2,
        [GraphType.wordCloud]: 4,
    },
    minimumHeight: {
        [GraphType.bar]: 2,
        [GraphType.pie]: 2,
        [GraphType.mixed]: 2,
        [GraphType.bubble]: 3,
        [GraphType.area]: 2,
        [GraphType.table]: 2,
        [GraphType.line]: 2,
        [GraphType.gauge]: 2,
        [GraphType.graph]: 4,
        [GraphType.scatterPlot]: 3,
        [GraphType.map]: 4,
        [GraphType.brush]: 3,
        [GraphType.dynamicLoaded]: 2,
        [GraphType.radar]: 2,
        [GraphType.number]: 1,
        [GraphType.wordCloud]: 3,
    },
    normalizeXAxes: [
        GraphType.bar,
        GraphType.line,
        GraphType.mixed,
        GraphType.brush,
        GraphType.bubble,
        GraphType.area,
        GraphType.graph,
        GraphType.radar,
        GraphType.number,
        GraphType.wordCloud,
    ],
    numericGraphTypes: [
        GraphType.bar,
        GraphType.area,
        GraphType.brush,
        GraphType.line,
        GraphType.pie,
        GraphType.scatterPlot,
        GraphType.radar,
        GraphType.mixed,
    ],
};
