var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class Widgets {
    constructor() {
        this.paths = Object.freeze({
            getWidgetsForContainer: (containertype, containerid) => `api/widgets/${encodeURIComponent(containertype)}/${encodeURIComponent(containerid)}`,
            getWidgetDataForContainer: (containertype, containerid, widgetid) => `api/widgets/${encodeURIComponent(containertype)}/${encodeURIComponent(containerid)}/${encodeURIComponent(widgetid)}/data`,
            get: (id) => `api/widgets/${encodeURIComponent(id)}`,
            getWidgetData: (id) => `api/widgets/${encodeURIComponent(id)}/data`,
            previewWidgetData: (id) => `api/widgets/${encodeURIComponent(id)}/previewData`,
            exportWidgetData: (id) => `api/widgets/${encodeURIComponent(id)}/exportData`,
            create: () => `api/widgets`,
            edit: () => `api/widgets`,
            saveDraft: (id) => `api/widgets/${encodeURIComponent(id)}/savedraft`,
            moveWidget: (id) => `api/widgets/${encodeURIComponent(id)}/move`,
            delete: (id) => `api/widgets/${encodeURIComponent(id)}`,
            deleteAllFromGroup: (groupid) => `api/widgets/deletefromgroup/${encodeURIComponent(groupid)}`,
            graphTypes: () => `api/widgets/graphtypes`,
            getDashboardFilters: () => `api/widgets/dashboardfilters`,
            getColorThemes: () => `api/widgets/themes`,
            createColorTheme: () => `api/widgets/themes`,
            editColorTheme: () => `api/widgets/themes`,
            deleteColorTheme: (id) => `api/widgets/themes/${encodeURIComponent(id)}`,
        });
    }
    /**
     * Widgets.GetWidgetsForContainer "GET api/widgets/{containerType}/{containerId}"
     *
     * Get all widgets belonging to the given container
     * @param {string} containertype
     * @param {Guid} containerid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetModel[]>>} List of widgets
     */
    getWidgetsForContainer(containertype, containerid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${containertype === undefined || containertype === null ? "" : encodeURIComponent(containertype)}/${containerid === undefined || containerid === null ? "" : encodeURIComponent(containerid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.GetWidgetDataForContainer "POST api/widgets/{containerType}/{containerId}/{widgetId}/data"
     *
     * Fetch data for the widget with the given id in the context of the given container
     * @param {string} containertype
     * @param {Guid} containerid
     * @param {Guid} widgetid
     * @param {WidgetGraphDataSelectionModel} model Data selection model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetDataModel>>} Mapped widget data
     */
    getWidgetDataForContainer(containertype, containerid, widgetid, model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${containertype === undefined || containertype === null ? "" : encodeURIComponent(containertype)}/${containerid === undefined || containerid === null ? "" : encodeURIComponent(containerid)}/${widgetid === undefined || widgetid === null ? "" : encodeURIComponent(widgetid)}/data`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.Get "GET api/widgets/{id}"
     *
     * Gets the widget with the provided id
     *
     * 404 If the dashboard with the provided id was not found
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetEditModel>>}
     */
    get(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.GetWidgetData "POST api/widgets/{id}/data"
     *
     * Gets the data for the widget with the provided id
     *
     * Executes the widgets query to fetch its data, taking into account the provided WidgetDataSelectionModel
     *
     * 404 If the dashboard with the provided id was not found
     *
     * 400 If model is not valid, or the id is not provided
     * @param {Guid} id
     * @param {WidgetGraphDataSelectionModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetDataModel>>}
     */
    getWidgetData(id, model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${id === undefined || id === null ? "" : encodeURIComponent(id)}/data`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.PreviewWidgetData "POST api/widgets/{id}/previewData"
     *
     * Gets the previewdata for the widget with the provided id
     *
     * Executes the widgets draftquery to fetch its data, taking into account the provided WidgetDataSelectionModel
     *
     * 404 If the widget with the provided id was not found
     *
     * 400 If model is not valid, or the id is not provided
     * @param {Guid} id
     * @param {WidgetGraphDataSelectionModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetDataModel>>}
     */
    previewWidgetData(id, model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${id === undefined || id === null ? "" : encodeURIComponent(id)}/previewData`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.ExportWidgetData "POST api/widgets/{id}/exportData"
     *
     * Downloads the exported data for the widget with the provided id
     *
     * Executes the widgets query to fetch its data, taking into account the provided WidgetExportDataSelectionModel
     *
     * 404 If the widget with the provided id was not found
     *
     * 400 If model is not valid, or the id is not provided
     * @param {Guid} id
     * @param {WidgetExportDataSelectionModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetDataModel>>}
     */
    exportWidgetData(id, model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${id === undefined || id === null ? "" : encodeURIComponent(id)}/exportData`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.Create "POST api/widgets"
     *
     * Inserts the provided widget into the database
     *
     * 404 If the provided dashboard does not exist
     * @param {Guid} dashboardid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    create(dashboardid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, dashboardid);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.Edit "PUT api/widgets"
     *
     * Updates the provided widget in the database
     *
     * 400 If the provided widget model is not valid, e.g. the Id property is missing
     *
     * 404 If the widget with the provided Id was not found
     * @param {WidgetEditModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<DashboardWidgetModel>>}
     */
    edit(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.SaveDraft "PUT api/widgets/{id}/savedraft"
     *
     * Save query as draft to preview widget data
     *
     * 400 If the provided data is not valid
     *
     * 404 If the widget with the provided id was not found
     * @param {Guid} id Id of the widget
     * @param {string} query Query to save as draft
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    saveDraft(id, query, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${id === undefined || id === null ? "" : encodeURIComponent(id)}/savedraft`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, query);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.MoveWidget "PUT api/widgets/{id}/move"
     *
     * 404 If the widget with the provided Id was not found
     * @param {Guid} id
     * @param {MovingDirection} direction
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    moveWidget(id, direction, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${id === undefined || id === null ? "" : encodeURIComponent(id)}/move`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, direction);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.Delete "DELETE api/widgets/{id}"
     *
     * Deletes the widget with the specified id
     *
     * 404 If the widget with the provided Id was not found
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    delete(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.DeleteAllFromGroup "DELETE api/widgets/deletefromgroup/{groupId}"
     *
     * Deletes all widgets in te WidgetGroup with the specified groupId.
     * Returns the number of widgets that were deleted.
     *
     * 404 If the WidgetGroup with the provided Id was not found
     * @param {Guid} groupid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<number>>} The number of deleted widgets
     */
    deleteAllFromGroup(groupid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/deletefromgroup/${groupid === undefined || groupid === null ? "" : encodeURIComponent(groupid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.GraphTypes "GET api/widgets/graphtypes"
     *
     * Get all available graphtypes
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<GraphTypeModel[]>>}
     */
    graphTypes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/graphtypes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.GetDashboardFilters "GET api/widgets/dashboardfilters"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetFilterCollectionModel>>}
     */
    getDashboardFilters(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/dashboardfilters`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.GetColorThemes "GET api/widgets/themes"
     *
     * Get all available color themes
     *
     * Used in widget editor, therefor ignores whether or not the user has the WidgetThemes permission
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetColorThemeModel[]>>}
     */
    getColorThemes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/themes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.CreateColorTheme "POST api/widgets/themes"
     *
     * Inserts the provided color theme into the database
     *
     * 400 If model is not valid
     * @param {WidgetColorThemeEditModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    createColorTheme(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/themes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.EditColorTheme "PUT api/widgets/themes"
     *
     * Updates the provided color theme
     *
     * 400 If model is not valid
     *
     * 404 If the theme with the provided Id was not found
     * @param {WidgetColorThemeEditModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    editColorTheme(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/themes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Widgets.DeleteColorTheme "DELETE api/widgets/themes/{id}"
     *
     * Deletes the color theme with the provided id
     *
     * 404 If the theme with the provided Id was not found
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    deleteColorTheme(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/widgets/themes/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const widgets = new Widgets();
export { widgets as default, Widgets };
