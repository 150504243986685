export var GraphType;
(function (GraphType) {
    GraphType[GraphType["draft"] = 0] = "draft";
    GraphType[GraphType["bar"] = 1] = "bar";
    GraphType[GraphType["pie"] = 2] = "pie";
    GraphType[GraphType["mixed"] = 3] = "mixed";
    GraphType[GraphType["bubble"] = 4] = "bubble";
    GraphType[GraphType["area"] = 5] = "area";
    GraphType[GraphType["candleStick"] = 6] = "candleStick";
    GraphType[GraphType["table"] = 7] = "table";
    GraphType[GraphType["line"] = 8] = "line";
    GraphType[GraphType["gauge"] = 9] = "gauge";
    GraphType[GraphType["graph"] = 10] = "graph";
    GraphType[GraphType["scatterPlot"] = 11] = "scatterPlot";
    GraphType[GraphType["funnel"] = 12] = "funnel";
    GraphType[GraphType["map"] = 13] = "map";
    GraphType[GraphType["brush"] = 14] = "brush";
    GraphType[GraphType["dynamicLoaded"] = 15] = "dynamicLoaded";
    GraphType[GraphType["radar"] = 16] = "radar";
    GraphType[GraphType["number"] = 17] = "number";
    GraphType[GraphType["wordCloud"] = 18] = "wordCloud";
})(GraphType || (GraphType = {}));
