import {reactive, ref} from 'vue';
import {MissingPropertyError} from '@app/utils/errors';

/**
 * Class for managing dashboard filter instances, creates and tracks active instances
 */
export class FilterManager {
  /** @type {Object} */
  router = undefined;

  /** @type {Array<Filter>} */
  static filters = [];

  /** @type {Array<Filter>} */
  static get filtersWithValues() {
    return this.filters.filter(filter => filter.hasValue);
  }

  /**
   * Readonly selected startDate
   *
   * @type {string|undefined}
   */
  static get startDate() {
    return Window.runtime.store.getters['dashboards/getDateRangeFilter']?.startDate;
  }

  /**
   * Readonly selected endDate
   *
   * @type {string|undefined}
   */
  static get endDate() {
    return Window.runtime.store.getters['dashboards/getDateRangeFilter']?.endDate;
  }

  /**
   * Readonly selected divisionId
   *
   * @type {string|undefined}
   */
  static get divisionId() {
    return Window.runtime.store.getters['areas/getSelectedAreaId'];
  }

  /**
   * Create a new tracked filter instance
   *
   * @returns {Filter}
   */
  static create(definition, router) {
    const filter = new Filter(definition, router);
    this.filters.push(filter);
    this.router = router;
    return filter;
  }

  /**
   * Clear all registered filters, reset tracked filters
   */
  static reset() {
    for (const filter of this.filters) filter.clearValue();
    this.filters = [];
  }
}

class Filter {
  widgetColumnMap;
  supportedFilterIds;
  value = reactive([]);
  options = ref([]);

  router = undefined;
  /** @type {string} */
  id = undefined;

  /** @type {string} */
  comparison = undefined;

  /** @type {string} */
  name = undefined;

  /** @type {string} */
  type = undefined;

  constructor(filter, router) {
    if (!filter?.group?.filterGroupId) throw new MissingPropertyError('filter.group.filterGroupId');
    if (!filter?.columnIds) throw new MissingPropertyError('filter.columnIds');

    // allow us to be used as a filter
    Object.assign(this, {
      // defaults, expected to be overriden
      name: filter.group.filterGroupId,
      comparison: 'equals',
      type: 'String',

      ...filter.group,
      id: filter.group.filterGroupId,
    });

    this.router = router;
    this.supportedFilterIds = filter.columnIds.map(columnId => this.#buildFilterId(columnId, this.comparison));
    this.widgetColumnMap = new Map();

    const query = this.router.currentRoute.value.query;

    this.clearValue();

    if (query[this.key]) this.setValue(query[this.key]);
  }

  /** Serialize the filter to a DTO object */
  serializeToDto() {
    // metadata
    return {
      id: this.id,
      comparison: this.comparison,
      name: this.name,
      type: this.type,

      // actual data
      key: this.key,
      value: this.getValue(),
    };
  }

  /**
   * Returns true when a non-null/undefined value has been assigned to the filter
   * @return {boolean}
   */
  get hasValue() {
    const value = this.value[0] ?? undefined;
    return value !== undefined && value !== '';
  }

  /**
   * Returns true when the filter has at least one registered widget
   * @return {boolean}
   */
  get hasWidgets() {
    return this.widgetColumnMap.size !== 0;
  }

  get key() {
    let key = `${this.id}-${this.comparison}`;
    if (this.comparison == 'in') {
      //mark key as array:
      key = `${key}[]`;
    }
    return key;
  }

  /**
   * Returns all registered widgets
   * @return {unknown[]}
   */
  get widgets() {
    return Array.from(this.widgetColumnMap.keys());
  }

  get getOptions() {
    return this.options;
  }

  /**
   * Attempt to register a widget as a consumer of this filter
   * does nothing when the widget is not supported
   * @param widget
   */
  registerWidget(widget) {
    if (!widget.dashboardFilters?.find) return;

    const widgetFilter = widget.dashboardFilters.find(widgetFilter =>
      this.supportedFilterIds.includes(this.#buildFilterId(widgetFilter.id, widgetFilter.comparison))
    );

    if (widgetFilter?.id) this.widgetColumnMap.set(widget, widgetFilter.id);
  }

  /**
   * Clear the filters value
   */
  clearValue() {
    this.value = [];
  }

  /**
   * Set the filter value as single value
   * @param value
   */
  setValue(value) {
    this.router.replace({
      query: {
        ...this.router.currentRoute.query,
        [this.key]: value ? value : undefined,
      },
    });

    if (Array.isArray(value)) {
      this.value = value;
    } else {
      this.value = value != undefined ? [value] : [];
    }
  }

  /**
   * Retrieve the filter value as array
   * @return {*}
   */
  getValue() {
    return this.value;
  }

  /**
   * Get the columnId to apply the filter value to for the given widget, returns undefined when widget has not been registered
   * @param widget
   * @return {*}
   */
  getWidgetColumn(widget) {
    return this.widgetColumnMap.get(widget);
  }

  #buildFilterId(columnId, comparison) {
    return `${columnId}|${comparison}`;
  }
}
