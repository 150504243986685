/** A validation class to return information during validate functions
 *
 *  Add error/critical/warning to the instance or current session.
 *  Use reset() to return the list of issues and reset the session;
 *
 *  Since it extends array, you can push/filter/deconstruct as needed.
 */
class Validation extends Array {
    constructor() {
        super(...arguments);
        this.context = undefined;
    }
    static warning(reason, group, context) {
        return Validation.current.warning(group, reason, context);
    }
    static error(reason, group, context) {
        return Validation.current.error(group, reason, context);
    }
    static critical(reason, group, context) {
        return Validation.current.critical(group, reason, context);
    }
    static success() {
        return undefined;
    }
    warning(reason, group, context) {
        return this.write(group, 'warning', reason, context);
    }
    error(reason, group, context) {
        return this.write(group, 'error', reason, context);
    }
    critical(reason, group, context) {
        return this.write(group, 'critical', reason, context);
    }
    success() {
        return undefined;
    }
    write(group, level, reason, context) {
        const entry = {
            group,
            level,
            reason,
            context: context || this.context,
        };
        this.push(entry);
        return entry;
    }
    get hasErrors() {
        return this.length > 0;
    }
    get passed() {
        return !this.hasErrors;
    }
    /** return total result */
    reset() {
        const result = [...this];
        this.length = 0;
        this.context = undefined;
        return result;
    }
}
Validation.current = new Validation();
export default Validation.current;
