var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import queryBuilder from '../../../app/api-generated/queryBuilder';
import { Contract } from '../../utils/contract';
import { getFilterTypes } from './filtertypes';
import { getFilterValueSources } from './filtervaluesources';
import { cleanType } from './util';
import { GraphType } from '../../typedefs/dashboards';
import { createResetMutation } from '@dnx/core';
import { QueryFilterValueSource } from '../../../app/api-generated/enums';
import { DateTime } from 'luxon';
import Uuid from '../../utils/uuid';
import _ from 'lodash';
import { T } from '@dnx/core';
const version = '0.1.alpha';
const newSequence = () => ({
    isInvisible: false,
    displayName: undefined,
    calcType: undefined,
    type: undefined,
});
const newColumn = () => ({
    column: undefined,
    aggregate: null,
    sequence: newSequence(),
});
const newFilter = () => ({
    valueSource: QueryFilterValueSource.Default,
    column: null,
    value: {},
    comparison: null,
});
const newFilterSet = () => ({
    filters: [newFilter()],
});
const mapToStorage = state => {
    //if one of the group's columns is empty, then group considered invalid and being filtered out
    const filteredGroups = state.groups.filter(group => {
        return !group.columns.find(column => {
            return !column.column;
        });
    });
    return {
        version,
        groups: filteredGroups
            .map(group => ({
            columns: group.columns.map(column => ({
                aggregate: column.aggregate && column.aggregate.key && { operation: column.aggregate.key },
                column: column.column && column.column.id,
            })),
            filterSets: group.filterSets
                .map(filterSet => ({
                filters: filterSet.filters
                    .filter(filter => !!filter)
                    .map(filter => (Object.assign(Object.assign({}, filter), { column: filter.column && filter.column.id, value: Object.assign({}, filter.value), valueSource: QueryFilterValueSource[filter.valueSource] }))),
            }))
                .filter(filterSet => filterSet.filters && filterSet.filters.length > 0),
        }))
            .filter(group => group.columns),
    };
};
const checkIndexBounds = (state, { groupIndex, columnIndex, filterSetIndex, filterIndex }) => {
    if (typeof groupIndex !== 'undefined') {
        if (groupIndex < 0 || groupIndex >= state.groups.length) {
            return false;
        }
        const group = state.groups[groupIndex];
        if (typeof columnIndex !== 'undefined') {
            if (columnIndex < 0 || columnIndex >= group.columns.length) {
                return false;
            }
        }
        if (typeof filterSetIndex !== 'undefined') {
            if (filterSetIndex < 0 || filterSetIndex >= group.filterSets.length) {
                return false;
            }
            const filterSet = group.filterSets[filterSetIndex];
            if (typeof filterIndex !== 'undefined') {
                if (filterIndex < 0 || filterIndex >= filterSet.length) {
                    return false;
                }
            }
        }
    }
    return true;
};
const GET_INITIAL_STATE = () => ({
    queryModel: {},
    groups: [],
    queryText: [],
    modifiers: [],
    metadata: {
        isLoaded: false,
        collections: {},
        columns: {},
        related: [],
    },
    widget: undefined,
});
const widgetAwaitable = new Contract();
const setSelectableCollections = (state, { groupIndex, columnIndex }, geo) => {
    var _a, _b, _c;
    if (((_a = state.groups) === null || _a === void 0 ? void 0 : _a.length) === false) {
        return [];
    }
    const group = state.groups[groupIndex];
    const selectableCollections = new Set();
    //Building the options list based on the previous columns collection.
    //looping through all previous columns to get the related collections of the selected collection and adding them to the selectableCollections
    for (let i = 0; i < (columnIndex !== null && columnIndex !== void 0 ? columnIndex : group.columns.length); i++) {
        const column = group.columns[i];
        if (!((_c = (_b = column.column) === null || _b === void 0 ? void 0 : _b.collection) === null || _c === void 0 ? void 0 : _c.isPrimary) && geo)
            return;
        //Relations aren't guaranteed - ensure our collection is registered as available
        selectableCollections.add(column.column.collection);
        //Find and register direct relations of our collection
        state.metadata.related.forEach(c => {
            if (c.primaryCollection === column.column.collection.id || c.foreignCollection === column.column.collection.id) {
                selectableCollections.add(state.metadata.collections[c.primaryCollection]);
                selectableCollections.add(state.metadata.collections[c.foreignCollection]);
            }
        });
    }
    return Array.from(selectableCollections);
};
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    actions: {
        /** Get and cache all collection data in an immutable structure */
        loadCollectionMetadata(_a) {
            return __awaiter(this, arguments, void 0, function* ({ state, commit }) {
                if (state.metadata.isLoaded) {
                    return;
                }
                const metadata = (yield queryBuilder.collections()).value;
                commit('updateMetadata', { metadata });
            });
        },
        loadModifiers(ctx) {
            return __awaiter(this, void 0, void 0, function* () {
                if (ctx.state.modifiers.length) {
                    return;
                }
                const mod = (yield queryBuilder.modifiers()).value;
                const type = {
                    1: 'category',
                    2: 'numeric',
                    3: 'datetime',
                };
                const modifiers = mod.map(x => {
                    return {
                        translationKey: x.displayName,
                        key: x.name,
                        type: x.type ? type[x.type] : null,
                        applicableTo: x.applicableTo,
                    };
                });
                ctx.commit('updateModifiers', { modifiers });
            });
        },
        getQueryText(ctx) {
            return __awaiter(this, void 0, void 0, function* () {
                const model = mapToStorage(ctx.state);
                const query = (yield queryBuilder.getQuery(model)).value;
                ctx.commit('updateQueryText', { query });
            });
        },
    },
    getters: {
        getState(state) {
            return mapToStorage(state);
        },
        getGroups(state) {
            return state.groups;
        },
        getPreviewState(state, getters, rootState, rootGetters) {
            const storageFormat = mapToStorage(state);
            const defaultDateRange = rootGetters['dashboards/getDateRangeFilter'];
            //At a later stage this should be configurable
            //Daterange is always assumed to be available/valid in dashboarding context
            const dashboardDateRange = JSON.stringify(defaultDateRange.startDate
                ? [
                    DateTime.fromJSDate(new Date(defaultDateRange.startDate)).toFormat(`yyyy-MM-dd 00:00:00`),
                    DateTime.fromJSDate(new Date(defaultDateRange.endDate)).toFormat(`yyyy-MM-dd 23:59:59`),
                ]
                : [
                    DateTime.now().minus({ days: 14 }).startOf('day').toFormat('yyyy-MM-dd 00:00:00'),
                    DateTime.now().endOf('day').toFormat('yyyy-MM-dd 23:59:59'),
                ]);
            storageFormat.groups
                .flatMap(group => group.filterSets.flatMap(filterSet => filterSet.filters))
                .filter(filter => QueryFilterValueSource[filter.valueSource] == QueryFilterValueSource.DashboardDateRange)
                .forEach(filter => (filter.value.exact = dashboardDateRange));
            return storageFormat;
        },
        getAvailableOptions(state) {
            function typeToResource(type) {
                type = type.replace('?', '').toUpperCase();
                return T(`QUERYBUILDER_COLUMN_TYPE_${type}`);
            }
            function mapToOptions(collections) {
                if (!Array.isArray(collections))
                    collections = [collections];
                return collections.map(c => ({
                    id: c.id,
                    label: c.translationKey ? T(c.translationKey, undefined, c.displayName) : c.displayName,
                    children: c.columns.map(x => {
                        const label = `${x.translationKey ? T(x.translationKey, undefined, x.displayName) : x.displayName} ${x.type ? ` (${typeToResource(x.type)})` : ''}`;
                        return {
                            id: x.id,
                            label: label,
                        };
                    }),
                }));
            }
            return ({ groupIndex, columnIndex }) => {
                var _a, _b;
                if (!checkIndexBounds(state, { groupIndex, columnIndex })) {
                    return;
                }
                const group = state.groups[groupIndex];
                const column = group.columns[columnIndex];
                const primaryCollections = Object.values(state.metadata.collections).filter(x => x.isPrimary);
                // Check if there are any other columns that point to a Primary collection
                const otherColumnsUsePrimaryCollection = group.columns.some((column, index) => { var _a, _b; return index !== columnIndex && ((_b = (_a = column.column) === null || _a === void 0 ? void 0 : _a.collection) === null || _b === void 0 ? void 0 : _b.isPrimary); });
                //Sole primary collection - only allow selection from current collection, having a single primary collection is a hard requirement
                //It's possible for columnIndex to be undefined, therefor we need to check if column is not undefined.
                if (column && ((_b = (_a = column.column) === null || _a === void 0 ? void 0 : _a.collection) === null || _b === void 0 ? void 0 : _b.isPrimary) && !otherColumnsUsePrimaryCollection) {
                    return mapToOptions(column.column.collection);
                }
                //First column - allow for selection of any primary collection
                if (columnIndex === 0) {
                    return mapToOptions(primaryCollections);
                }
                if (setSelectableCollections(state, { groupIndex, columnIndex }, true) == undefined) {
                    return;
                }
                else {
                    return mapToOptions(setSelectableCollections(state, { groupIndex, columnIndex }, true));
                }
            };
        },
        getSequences(state, getters) {
            if (!state.groups[0]) {
                //Not initialized yet
                return [];
            }
            if (getters.useColumnBasedSequences) {
                return state.groups[0].columns.map(column => column.sequence);
            }
            return state.groups.map(g => g.sequence);
        },
        widgetType(state, getters, rootState) {
            var _a, _b, _c;
            //When we're still initializing, take our cached widgets type
            return (_c = (_a = rootState.widgets.type) !== null && _a !== void 0 ? _a : (_b = state.widget) === null || _b === void 0 ? void 0 : _b.widgetType) !== null && _c !== void 0 ? _c : GraphType.draft;
        },
        isSupported(state, getters) {
            //Certain widgets utilize a custom (non query based) configuration, these widget types aren't supported by the QB
            const unsupportedWidgetTypes = [GraphType.map, GraphType.graph];
            return !unsupportedWidgetTypes.includes(getters.widgetType);
        },
        isSingleSeries(state, getters) {
            const singleSeriesWidgetTypes = [
                GraphType.draft,
                GraphType.table,
                GraphType.pie,
                GraphType.gauge,
                GraphType.number,
            ];
            //TODO: Move to WidgetTypeDefinitions
            return singleSeriesWidgetTypes.includes(getters.widgetType);
        },
        useColumnBasedSequences(state, getters) {
            return getters.widgetType === GraphType.table;
        },
        supportsSequenceTypes(state, getters) {
            return getters.widgetType === GraphType.mixed;
        },
        isValid(state) {
            //Require at least one group with a column selected
            return (state.groups || []).some(group => group.columns.some(column => column.column));
        },
        getWidgetAwaitable() {
            return widgetAwaitable;
        },
    },
    mutations: {
        reset() {
            // eslint-disable-next-line prefer-rest-params
            createResetMutation(GET_INITIAL_STATE).apply(this, arguments);
            this.commit('queryBuilder/resetAwaitable');
        },
        setWidget(state, widget) {
            state.widget = widget;
            if (!widget.queryModel) {
                //Ensure we have at least a single group
                this.commit('queryBuilder/addGroup');
                widgetAwaitable.resolve();
                return;
            }
            state.queryModel = widget.queryModel;
            widgetAwaitable.resolve();
        },
        resetAwaitable() {
            widgetAwaitable.reset();
        },
        addGroup(state, columnAmount) {
            const availableCollections = [];
            Array.prototype.push.apply(availableCollections, Object.values(state.metadata.collections).filter(x => x.name.startsWith('DEMO_')));
            const group = {
                id: Uuid.NewUuid().toString(),
                activeCollection: null,
                availableCollections,
                columns: [newColumn()],
                filterSets: [],
                sequence: newSequence(),
            };
            while (group.columns.length < columnAmount) {
                group.columns.push(newColumn());
            }
            state.groups.push(group);
        },
        removeGroup(state, { groupIndex }) {
            if (checkIndexBounds(state, { groupIndex })) {
                if (state.groups.length > 0)
                    state.groups.splice(groupIndex, 1);
            }
        },
        duplicateGroup(state, { groupIndex }) {
            if (checkIndexBounds(state, { groupIndex })) {
                const newGroup = _.cloneDeep(state.groups[groupIndex]);
                newGroup.id = Uuid.NewUuid().toString();
                state.groups.splice(groupIndex + 1, 0, newGroup);
            }
        },
        setColumn(state, { groupIndex, columnIndex, $event: columnId }) {
            if (checkIndexBounds(state, { groupIndex, columnIndex })) {
                //When our columnId was cleared, take the default value from a new column
                const group = state.groups[groupIndex];
                const columnMetadata = columnId ? state.metadata.columns[columnId] : newColumn().column;
                const column = group.columns[columnIndex];
                column.column = columnMetadata;
                // for ressetting filters based on what is available
                if (state.groups[0].columns[0].column) {
                    const selectableArray = setSelectableCollections(state, { groupIndex, columnIndex: 1 }, false);
                    const filterArrayFromSelectable = [];
                    selectableArray.map(a => {
                        a.columns.map(c => {
                            filterArrayFromSelectable.push(c.id);
                        });
                    });
                    if (state.groups[groupIndex].filterSets.length) {
                        state.groups[groupIndex].filterSets.forEach(y => y.filters.forEach((x, index) => {
                            if (x.column == undefined) {
                                return;
                            }
                            const isValid = filterArrayFromSelectable.includes(x.column.id);
                            if (isValid) {
                                return;
                            }
                            else {
                                y.filters[index] = newFilter();
                            }
                        }));
                    }
                }
                const columnAmount = group.columns.filter(x => x.column).length;
                if (columnAmount == 0) {
                    group.filterSets = [];
                }
                //Tables require property names for mapping
                column.sequence.label = columnMetadata === null || columnMetadata === void 0 ? void 0 : columnMetadata.displayName;
                column.sequence.propertyName = columnMetadata === null || columnMetadata === void 0 ? void 0 : columnMetadata.name;
                // boolInSelectableArray = false;
                this.commit('queryBuilder/syncWidgetXAxisType');
            }
        },
        setColumnModifier(state, { groupIndex, columnIndex, modifier }) {
            if (checkIndexBounds(state, { groupIndex, columnIndex })) {
                state.groups[groupIndex].columns[columnIndex].aggregate = modifier;
                this.commit('queryBuilder/syncWidgetXAxisType');
            }
        },
        setColumnCalculationType(state, { sequence, calcType }) {
            sequence.calcType = calcType;
        },
        //Assign a displayname to the given sequence
        setSequenceName(state, { sequence, name }) {
            sequence.displayName = name;
        },
        //Mark the given sequence as invisible causing it to not be rendered by the widget
        toggleSequenceInvisible(state, { sequence }) {
            sequence.isInvisible = !sequence.isInvisible;
        },
        //Update a widgets sequence type (eg. bar, line, etc.). Used for widget types supporting a multitude of series types
        setSequenceType(state, { sequence, type }) {
            sequence.type = type;
        },
        setFilterValueSource(state, { groupIndex, filterSetIndex, filterIndex, valueSource }) {
            if (!checkIndexBounds(state, { groupIndex, filterSetIndex, filterIndex }))
                return;
            state.groups[groupIndex].filterSets[filterSetIndex].filters[filterIndex].valueSource = valueSource;
            this.commit('queryBuilder/ensureFilterCorrectness', {
                groupIndex,
                filterSetIndex,
                filterIndex,
            });
        },
        setFilterColumn(state, { groupIndex, filterSetIndex, filterIndex, columnId }) {
            if (!checkIndexBounds(state, { groupIndex, filterSetIndex, filterIndex }))
                return;
            const filter = state.groups[groupIndex].filterSets[filterSetIndex].filters[filterIndex];
            const filterColumn = state.metadata.columns[columnId];
            const oldFilterColumn = filter.column;
            //Reset to defaults
            if (!filterColumn) {
                state.groups[groupIndex].filterSets[filterSetIndex].filters[filterIndex] = newFilter();
                return;
            }
            filter.column = filterColumn;
            //Reset value when switching column type
            //column type values are expected to not be compatible (int can be converted to string but not vice versa)
            if (cleanType(oldFilterColumn) !== cleanType(filterColumn)) {
                filter.value.exact = undefined;
                //When switching from non-datetime to datetime, also attempt to set defaults for our type/valuesource
                //should we generify our defaults?
                if (cleanType(filterColumn) === 'datetime') {
                    filter.comparison = 'between';
                    filter.valueSource = QueryFilterValueSource.DashboardDateRange;
                }
            }
            this.commit('queryBuilder/ensureFilterCorrectness', {
                groupIndex,
                filterSetIndex,
                filterIndex,
            });
        },
        setFilterComparison(state, { groupIndex, filterSetIndex, filterIndex, comparison }) {
            if (!checkIndexBounds(state, { groupIndex, filterSetIndex, filterIndex }))
                return;
            const filter = state.groups[groupIndex].filterSets[filterSetIndex].filters[filterIndex];
            const oldComparison = filter.comparison;
            filter.comparison = comparison;
            //We switched from single-value to range - value is expected to be incompatible, reset
            if (oldComparison === 'between' || comparison === 'between')
                filter.value.exact = undefined;
            this.commit('queryBuilder/ensureFilterCorrectness', {
                groupIndex,
                filterSetIndex,
                filterIndex,
            });
        },
        setFilterValue(state, { groupIndex, filterSetIndex, filterIndex, value }) {
            if (checkIndexBounds(state, { groupIndex, filterSetIndex, filterIndex })) {
                let val = value;
                if (value) {
                    if (Array.isArray(value)) {
                        val = JSON.stringify(value);
                    }
                    else {
                        val = value.toString();
                    }
                }
                state.groups[groupIndex].filterSets[filterSetIndex].filters[filterIndex].value.exact = val;
            }
        },
        ensureFilterCorrectness(state, { groupIndex, filterSetIndex, filterIndex }) {
            if (!checkIndexBounds(state, { groupIndex, filterSetIndex, filterIndex }))
                return;
            const filter = state.groups[groupIndex].filterSets[filterSetIndex].filters[filterIndex];
            //Is our comparison still available?
            const filterTypes = getFilterTypes(filter);
            const defaultComparison = filterTypes[0];
            if (!filterTypes.includes(filter.comparison))
                filter.comparison = defaultComparison;
            //Is our ValueSource still valid?
            const valueSources = getFilterValueSources(filter);
            if (!valueSources.includes(filter.valueSource))
                filter.valueSource = QueryFilterValueSource.DashboardCustom;
            if (filter.comparison === 'between' && filter.valueSource === QueryFilterValueSource.DashboardDateRange) {
                filter.value.exact = undefined;
            }
        },
        addColumn(state, { groupIndex }) {
            if (checkIndexBounds(state, { groupIndex })) {
                state.groups[groupIndex].columns.push(newColumn());
            }
        },
        removeColumn(state, { groupIndex, columnIndex }) {
            if (checkIndexBounds(state, { groupIndex, columnIndex })) {
                state.groups[groupIndex].columns.splice(columnIndex, 1);
                if (state.groups[groupIndex].columns.length === 0) {
                    //Re-add a column post removal
                    //this ensures a clean column will be rendered in case our group was the only group (and thus not actually removed)
                    this.commit('queryBuilder/addColumn', { groupIndex });
                    this.commit('queryBuilder/removeGroup', { groupIndex });
                    this.commit('queryBuilder/syncWidgetXAxisType');
                }
            }
        },
        addFilterSet(state, { groupIndex }) {
            if (checkIndexBounds(state, { groupIndex })) {
                state.groups[groupIndex].filterSets.push(newFilterSet());
            }
        },
        removeFilterSet(state, { groupIndex, filterSetIndex }) {
            if (checkIndexBounds(state, { groupIndex, filterSetIndex })) {
                state.groups[groupIndex].filterSets.splice(filterSetIndex, 1);
            }
        },
        resetFilters(state, { groupIndex }) {
            state.groups[groupIndex].filterSets = [];
        },
        addFilter(state, { groupIndex, filterSetIndex }) {
            if (checkIndexBounds(state, { groupIndex, filterSetIndex })) {
                state.groups[groupIndex].filterSets[filterSetIndex].filters.push(newFilter());
            }
        },
        removeFilter(state, { groupIndex, filterSetIndex, filterIndex }) {
            if (checkIndexBounds(state, { groupIndex, filterSetIndex, filterIndex })) {
                if (state.groups[groupIndex].filterSets[filterSetIndex].filters.length === 1) {
                    this.commit('queryBuilder/removeFilterSet', {
                        groupIndex,
                        filterSetIndex,
                    });
                }
                else {
                    state.groups[groupIndex].filterSets[filterSetIndex].filters.splice(filterIndex, 1);
                }
            }
        },
        updateMetadata(state, { metadata }) {
            state.metadata.isLoaded = true;
            const collections = {};
            const columns = {};
            const related = [];
            //Even within the context of a single mutator function, each state modification seems to trigger a synchronous recalculation of our getters
            //leading to a noticeable UI freeze when setting collections/columns/related on an individual basis
            //thus we start off with non-reactive objects -> assign them to our state after we're done filling them
            for (const collection of metadata.collections) {
                collections[collection.id] = Object.freeze(collection);
                for (const column of collection.columns) {
                    columns[column.id] = Object.freeze(Object.assign(Object.assign({}, column), { collection }));
                }
            }
            for (const relation of metadata.relations) {
                related.push(Object.freeze(Object.assign({}, relation)));
            }
            state.metadata.collections = collections;
            state.metadata.columns = columns;
            state.metadata.related = related;
        },
        updateModifiers(state, { modifiers }) {
            state.modifiers = [...modifiers];
        },
        syncWidgetXAxisType(state) {
            //Attempt to sync the xaxistype associated with our data with the underlying widgetstore
            var _a, _b, _c;
            if (!checkIndexBounds(state, { groupIndex: 0, columnIndex: 0 })) {
                this.commit('widgets/setXaxisType', undefined, { root: true });
                return;
            }
            //Our order of preference goes from modifier -> column
            //Our first column will determine what our xaxis type will be
            const firstColumn = state.groups[0].columns[0];
            //Modifiers already have their xaxistype fully specified
            if ((_a = firstColumn.aggregate) === null || _a === void 0 ? void 0 : _a.type) {
                this.commit('widgets/setXaxisType', firstColumn.aggregate.type, {
                    root: true,
                });
                return;
            }
            //Columns utilize .NET types
            const columnType = (_c = (_b = firstColumn.column) === null || _b === void 0 ? void 0 : _b.type) === null || _c === void 0 ? void 0 : _c.toLowerCase();
            //First column not configured, reset back to defualt
            if (!columnType) {
                this.commit('widgets/setXaxisType', undefined, { root: true });
                return;
            }
            //Attempt to resolve xaxistype by our datatype, use `includes` to account for nullability marker in datatypes
            if (columnType.includes('datetime'))
                this.commit('widgets/setXaxisType', 'datetime', { root: true });
            else if (columnType.includes('string'))
                this.commit('widgets/setXaxisType', 'category', { root: true });
            else
                this.commit('widgets/setXaxisType', 'numeric', { root: true });
        },
        moveGroup(state, { fromIndex, toIndex }) {
            if (toIndex < 0 || toIndex == state.groups.length.count)
                return;
            const group = state.groups.splice(fromIndex, 1)[0];
            state.groups.splice(toIndex, 0, group);
        },
        setGroupColumns(state, { groupIndex, newValue }) {
            state.groups[groupIndex].columns = newValue;
        },
        updateWidget(state) {
            var _a;
            if (!state.queryModel.groups)
                return;
            //Map from storage
            //Sequences are located by absolute index based on column/group
            let sequenceIndex = 0;
            const sequences = ((_a = state.widget) === null || _a === void 0 ? void 0 : _a.sequences) || [];
            const locateSequence = type => {
                if (type === 'group' && this.getters['queryBuilder/useColumnBasedSequences'])
                    return newSequence();
                if (type === 'column' && !this.getters['queryBuilder/useColumnBasedSequences'])
                    return newSequence();
                return Object.assign(Object.assign({}, newSequence()), sequences[sequenceIndex++]);
            };
            state.groups = state.queryModel.groups.map(group => (Object.assign(Object.assign({}, group), { id: Uuid.NewUuid().toString(), sequence: locateSequence('group'), columns: group.columns.map(x => {
                    var _a, _b;
                    return ({
                        aggregate: x.aggregate ? state.modifiers.find(y => y.key == x.aggregate.operation) : x.aggregate,
                        column: state.metadata.columns[x.column],
                        sequence: Object.assign(Object.assign({}, locateSequence('column')), { propertyName: (_a = state.metadata.columns[x.column]) === null || _a === void 0 ? void 0 : _a.name, label: (_b = state.metadata.columns[x.column]) === null || _b === void 0 ? void 0 : _b.displayName }),
                    });
                }), filterSets: (group.filterSets || []).map(y => ({
                    filters: y.filters.map(z => {
                        var _a;
                        return (Object.assign(Object.assign({}, z), { 
                            //Backend may apply different casing, attempt to normalize by looking up our value via our collection of valid values for the given column
                            comparison: (_a = getFilterTypes(state.metadata.columns[z.column])) === null || _a === void 0 ? void 0 : _a.find(type => { var _a; return type.toLowerCase() === ((_a = z.comparison) === null || _a === void 0 ? void 0 : _a.toLowerCase()); }), valueSource: QueryFilterValueSource[z.valueSource] || QueryFilterValueSource.Default, column: state.metadata.columns[z.column] }));
                    }),
                })), activeCollection: null, availableCollections: [] })));
        },
        updateQueryText(state, { query }) {
            state.queryText = query.queries;
        },
        copyFilterSets(state, { fromGroupIndex, toGroupIndex }) {
            state.groups[toGroupIndex].filterSets = state.groups[fromGroupIndex].filterSets;
        },
        normalizeXAxes(state) {
            if (!state.groups.length)
                return;
            state.groups.forEach(group => {
                group.columns[0] = state.groups[0].columns[0];
            });
        },
    },
};
